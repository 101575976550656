<template>
    <div style="position: relative;">
        <v-row row wrap grid-list-lg>
            <v-col cols="12" sm="6" md="4">
                <v-menu
                        v-model="menu_from"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="date_from"
                                label="Od"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="date_from"
                            @input="menu_from = false"
                            locale="cs-cz"
                            first-day-of-week="1"
                            :min="min_date"
                            :max="max_date"
                    >
                        <v-btn color="primary" @click.prevent="date_from = getToday(); menu_from = false;">Dnes</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <v-menu
                        v-model="menu_to"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="date_to"
                                label="Do"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="date_to"
                            @input="menu_to = false"
                            locale="cs-cz"
                            first-day-of-week="1"
                            :min="min_date"
                            :max="max_date"
                    >
                        <v-btn color="primary" @click.prevent="date_to = getToday(); menu_to = false;">Dnes</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <h1 class="text-center text-sm-right">{{ total }}</h1>
            </v-col>
            <v-col v-if="showPartner" cols="12">
                <InfoAutocomplete v-model="partner" url="/api/partners/search" label="Partner"></InfoAutocomplete>
            </v-col>
        </v-row>

        <ApexChart type="line" :options="options" :series="series"></ApexChart>
    </div>
</template>

<script>
    import ApexChart from 'vue-apexcharts';
    import axios from 'axios';
    import InfoAutocomplete from "./InfoAutocomplete";
    import Utils from "../Utils";

    let cs = {
        "name": "cs",
        "options": {
            "months": ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
            "shortMonths": ["Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čvc", "Srp", "Zář", "Říj", "Lis", "Pro"],
            "days": ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
            "shortDays": ["Ned", "Pon", "Úte", "Stř", "Čtv", "Pát", "Sob"],
            "toolbar": {
                "exportToSVG": "Stáhnout SVG",
                "exportToPNG": "Stáhnout PNG",
                "menu": "Menu",
                "selection": "Výběr",
                "selectionZoom": "Přiblížení výběru",
                "zoomIn": "Přiblížit",
                "zoomOut": "Oddálit",
                "pan": "Posun",
                "reset": "Zrušit přiblížení"
            }
        }
    };

    export default {
        name: "LineChart",
        props: {
            url: {},
            label: {},
            showPartner: { type: Boolean, default: true }
        },
        components: {InfoAutocomplete, ApexChart},

        data: function() {
            return {
                options: {
                    chart: {
                        id: 'vuechart',
                        locales: [cs],
                        defaultLocale: 'cs'
                    },
                    xaxis: {
                        type: 'datetime'
                    },
                    yaxis: {
                        labels: {
                            offsetX: 10
                        }
                    }
                },
                series: [],
                total: 0,

                isLoading: false,

                partner: this.getPartner(),
                min_date: '2005-01-01',
                max_date: this.getToday(),
                menu_from: false,
                date_from: this.getDateFrom(),
                menu_to: false,
                date_to: this.getDateTo(),
            }
        },

        mounted() {
            this.$nextTick(function () {
                this.loadData();
            });
        },

        watch: {
            date_from() {
                window.localStorage.setItem('reports:date-from', this.date_from);
                this.loadData();
            },

            date_to() {
                window.localStorage.setItem('reports:date-to', this.date_to);
                this.loadData();
            },

            partner() {
                window.localStorage.setItem('reports:partner', JSON.stringify(this.partner));
                this.loadData();
            }
        },

        methods: {
            getToday() {
                return new Date().toISOString().substring(0, 10);
            },

            getDateFrom() {
                return (window.localStorage.getItem('reports:date-from') || this.getToday());
            },

            getDateTo() {
                return (window.localStorage.getItem('reports:date-to') || this.getToday());
            },

            getPartner() {
                let d = window.localStorage.getItem('reports:partner') || null;

                if (d) {
                    d = JSON.parse(d);
                }

                return d;
            },

            loadData() {
                if (this.isLoading) return;
                this.isLoading = true;
                let params = { date_from: this.date_from, date_to: this.date_to };
                if (this.partner) {
                    params.partner_id = this.partner.id;
                }
                axios.get(Utils.buildUrl([this.url]), {params: params})
                    .then((result) => {
                        this.series = [ { name: this.label, data: result.data.data } ];
                        this.total = result.data.total;
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => { this.isLoading = false; });
            }
        }
    }
</script>

<style scoped>

</style>
