<template>
    <v-dialog v-model="dialog">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nový uživatel</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container >
                    <v-row wrap>
                        <v-col cols="12">
                            <v-text-field v-model="editedItem.name" label="Jméno"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="editedItem.email" label="E-mail"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="editedItem.phone" label="Telefon"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="editedItem.password" label="Heslo" type="password"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row v-if="isAdmin">
                        <v-col cols="12">
                            <v-checkbox v-model="editedItem.is_admin" label="Administrátor?"></v-checkbox>
                        </v-col>
                    </v-row>

                    <v-row v-if="isAdmin" v-for="permission in availablePermissions">
                        <v-col cols="2">
                            <div class="permission-label">{{ permission.label }}</div>
                        </v-col>
                        <v-col v-for="action in permission.actions" cols="2">
                            <v-checkbox v-model="editedItem.permissions[permission.page]" :label="action" :value="action" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                <v-btn color="blue darken-1" text @click="save">Uložit a zavřít</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import User from "../models/User";
    import editableDialog from "../mixins/editableDialog";

    export default {
        name: "UserFormDialog",

        mixins: [editableDialog],

        data: () => {
            return {
                itemClass: User,

                defaultItem: {
                    name: '',
                    email: '@',
                    phone: '+420',
                    password: '',
                    is_admin: false,
                    permissions: {
                        contracts: [],
                        partners: [],
                        contacts: [],
                        places: [],
                        reports: [],
                        users: [],
                        orders: [],
                        setup: [],
                        invoices: [],
                        expenses: [],
                    },
                },

                availablePermissions: [
                    { page: 'contracts', label: 'Zakázky', actions: [ 'read' ] },
                    { page: 'partners', label: 'Partneři', actions: [ 'read' ] },
                    { page: 'contacts', label: 'Kontaktní osoby', actions: [ 'read' ] },
                    { page: 'places', label: 'Místa', actions: [ 'read' ] },
                    { page: 'reports', label: 'Reporty', actions: [ 'read' ] },
                    { page: 'users', label: 'Uživatelé', actions: [ 'read' ] },
                    { page: 'orders', label: 'Historie objednávek', actions: [ 'read' ] },
                    { page: 'invoices', label: 'Faktury', actions: [ 'read' ] },
                    { page: 'expenses', label: 'Náklady', actions: [ 'read' ] },
                    { page: 'setup', label: 'Nastavení', actions: [ 'read' ] },
                ],
            };
        },

        computed: {
            formTitle() {
                return !this.value ? 'Nový uživatel' : 'Úprava uživatele'
            },

            isAdmin() {
                return window.current_user.is_admin;
            },
        }

    }
</script>

<style>
    .permission-label {
        margin-top: 16px;
        padding-top: 4px;
    }
</style>
