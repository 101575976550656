<template>
    <v-dialog v-model="formDialog" v-if="contract">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ createExpenseLabel }}</v-btn>
        </template>
        <v-card>
            <v-card-title>Vyplňte nákladový formulář a zkontrolujte údaje</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <PartnerInfo :item="carrier.partner"></PartnerInfo>
                    </v-col>
                    <v-col cols="6">
                        <h3 class="mt-3 mb-3">Fakturační adresa</h3>
                        <table class="partner-table mb-5">
                            <tr><th>Ulice: </th><td>{{ invoicingAddress.street }}</td></tr>
                            <tr><th>Město: </th><td>{{ invoicingAddress.city }}</td></tr>
                            <tr><th>PSČ: </th><td>{{ invoicingAddress.zip }}</td></tr>
                            <tr><th>Země: </th><td>{{ invoicingAddress.country }}</td></tr>
                        </table>
                        <div v-if="client && client.attributes.invoicing_fakturoid_id">
                            Fakturoid Id: {{ client.attributes.invoicing_fakturoid_id }}
                        </div>
                        <div v-else>
                            <v-btn color="primary" dark class="mb-2" :disabled="registering_client" @click.prevent="registerClient(client)">Registrovat zákazníka ve fakturoidu</v-btn>
                        </div>
                    </v-col>
                </v-row>

<!--                <div v-if="!this.carrier.expense">-->
                <div>
                    <hr style="margin-top: 1rem; margin-bottom: 1rem;"/>
                    <v-row  wrap @drop.prevent="loadIsdoc" @dragenter.prevent="dragEnter = true" @dragover.prevent="dragEnter = true" @dragleave.prevent="dragEnter = false" @dragend.prevent="dragEnter = false" :class="{ dragging: dragEnter }">
                        <v-col cols="12">
                            <v-btn color="primary" dark @click.prevent="loadIsdoc">Načíst ISDOC</v-btn>
                            <span v-if="loadingIsdoc">Nahrávám...</span>
                        </v-col>
                        <v-col v-if="newAttachment" cols="12">
                            <v-icon @click.prevent="deleteNewAttachment" small>mdi-delete</v-icon>
                            {{ newAttachment.name }} ({{ (newAttachment.size / 1024 / 1024).toFixed(4) }} MB)
                        </v-col>
                    </v-row>
                    <hr style="margin-top: 1rem; margin-bottom: 1rem;" />
                </div>

                <v-row style="align-items: flex-end">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="4">
                                <v-checkbox v-model="expense.transferred_tax_liability" label="Přenesená DPH" class="mt-0 pt-2"></v-checkbox>
                            </v-col>
                            <v-col v-if="expense.transferred_tax_liability" cols="4">
                                <v-select
                                    v-model="expense.supply_code"
                                    :items="supplyCodes"
                                    label="Kód plnění"
                                    item-text="title"
                                    item-value="code"
                                    outlined
                                    hide-details
                                    dense
                                    class="mb-3"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="expense.original_number" label="Číslo dokladu uvedené na přijaté faktuře"></TextField>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="expense.variable_symbol" label="Variabilní symbol"></TextField>
                    </v-col>

                    <v-col cols="6">
                        <DateTimePicker :datetime="expense.issued_on" label="Datum vystavení" @input="expense.issued_on = $event" :usetime="false"></DateTimePicker>
                    </v-col>
                    <v-col cols="6">
                        <DateTimePicker :datetime="expense.taxable_fulfillment_due" label="Datum zdanitelného plnění" @input="expense.taxable_fulfillment_due = $event" :usetime="false"></DateTimePicker>
                    </v-col>

                    <v-col cols="6">
                        <DateTimePicker :datetime="expense.due_on" label="Splatnost" @input="expense.due_on = $event" :usetime="false"></DateTimePicker>
                    </v-col>
                    <v-col cols="6">
                        <TextField v-model="expense.currency" label="Měna (3 znaky dle ISO např. CZK, EUR, USD)"></TextField>
                    </v-col>

                    <v-col cols="6">
                        <v-select v-model="expense.document_type"
                                  item-text="title" item-value="value"
                                  :items="[
                                              { value: 'invoice', title: 'Faktura'},
                                              { value: 'bill', title: 'Účtenka'},
                                              { value: 'other', title: 'Jiný'}
                                          ]"
                                  label="Typ dokumentu"
                        ></v-select>
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="expense.payment_method"
                                  item-text="title" item-value="value"
                                  :items="[
                                              { value: 'bank', title: 'Banka'},
                                              { value: 'cash', title: 'Hotovost'},
                                              { value: 'cod', title: 'Dobírka'},
                                              { value: 'card', title: 'Karta'},
                                          ]"
                                  label="Platební metoda"
                        ></v-select>
                    </v-col>

                    <v-col cols="12">
                        <TextArea v-model="expense.description" label="Popis"></TextArea>
                    </v-col>

                    <v-col v-if="lines" v-for="(line, idx) in lines" cols="12">
                        <v-row>
                            <v-col cols="5">
                                <TextField v-model="line.name" label="Název"></TextField>
                            </v-col>
                            <v-col cols="2">
                                <TextField v-model="line.quantity" label="Množství"></TextField>
                            </v-col>
                            <v-col cols="2">
                                <TextField v-model="line.vat_rate" label="DPH"></TextField>
                            </v-col>
                            <v-col cols="2">
                                <TextField v-model="line.unit_price" label="Cena za jednotku"></TextField>
                            </v-col>
                            <v-col cols="1">
                                <v-btn color="primary" dark class="mb-2" @click.prevent="removeLine(idx)" :disabled="line.id"> - </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-btn color="primary" dark class="mb-2" @click.prevent="addLine()">Přidat řádek</v-btn>
                    </v-col>
                </v-row>

                 <v-row wrap @drop.prevent="addNewAttachment" @dragenter.prevent="dragEnter = true" @dragover.prevent="dragEnter = true" @dragleave.prevent="dragEnter = false" @dragend.prevent="dragEnter = false" :class="{ dragging: dragEnter }">
<!--                    <v-col v-if="editedItem.documents && editedItem.documents.length > 0">-->
<!--                        <b>Nahrané dokumenty</b>-->
<!--                        <br />-->
<!--                        <DocumentLinkList :documents="editedItem.documents"></DocumentLinkList>-->
<!--                    </v-col>-->
                    <v-col v-if="newAttachment" cols="12">
                        <v-icon @click.prevent="deleteNewAttachment" small>mdi-delete</v-icon>
                        {{ newAttachment.name }} ({{ (newAttachment.size / 1024 / 1024).toFixed(4) }} MB)
                    </v-col>
                    <v-col cols="12">
                        <v-btn color="primary" dark @click.prevent="addNewAttachment">Přiložit Soubor</v-btn>
                    </v-col>
                 </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="client && client.attributes.invoicing_fakturoid_id"
                       :disabled="creating_invoice" :loading="creating_invoice"
                       color="primary" dark class="mb-2" @click.prevent="createExpense">{{ createExpenseLabel }}</v-btn>
                <span v-else>Zákazník není zaregistrován ve fakturoidu.</span>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import date from '../mixins/date';
    import supply_codes from '../mixins/supplyCodes';
    import TextField from "./TextField.vue";
    import TextArea from "./TextArea.vue";
    import PartnerInfo from "./PartnerInfo.vue";
    import Partner from "../models/Partner";
    import Expense from "../models/Expense";
    import DateTimePicker from "./DateTimePicker.vue";
    import utils from "../mixins/utils";

    export default {
        name: "ExpenseDialog",
        components: {DateTimePicker, PartnerInfo, TextField, TextArea},
        mixins: [date, supply_codes, utils],
        props: {
            carrier: { required: true },
            contract: { required: true },
        },

        data: () => {
            return {
                formDialog: false,
                isLoading: false,
                loadingIsdoc: false,

                due: null,
                order_number: null,
                lines: [],

                expense: {
                    due_on: null,
                    original_number: null,
                    variable_symbol: null,
                    document_type: null,
                    issued_on: null,
                    taxable_fulfillment_due: null,
                    transferred_tax_liability: null,
                    supply_code: null,
                    currency: null,
                    payment_method: null,
                    description: null,
                },

                client: null,

                registering_client: false,
                creating_invoice: false,

                dragEnter: false,
                newAttachment: null,
            }
        },

        mounted() {
        },

        computed: {
            invoicingAddress() {
                if (!this.client) return {};

                const iaddress = this.client.invoicingAddress();
                if (!iaddress) return {};

                return iaddress;
            },

            createExpenseLabel() {
                if (this.carrier.expense && this.carrier.expense.fakturoid_id) {
                    return 'Aktualizovat';
                }

                return 'Vytvořit náklad';
            },
        },

        watch: {
            formDialog(value) {
                if (value !== true) {
                    return;
                }

                this.client = new Partner(this.carrier.partner);
                this.client.fetch();

                if (this.carrier.expense) {
                    this.expense.due_on = this.carrier.expense.data.due_on;
                    this.expense.original_number = this.carrier.expense.data.original_number;
                    this.expense.variable_symbol = this.carrier.expense.data.variable_symbol;
                    this.expense.document_type = this.carrier.expense.data.document_type;
                    this.expense.issued_on = this.carrier.expense.data.issued_on;
                    this.expense.taxable_fulfillment_due = this.carrier.expense.data.taxable_fulfillment_due;
                    this.expense.transferred_tax_liability = this.carrier.expense.data.transferred_tax_liability;
                    this.expense.supply_code = this.carrier.expense.data.supply_code;
                    this.expense.currency = this.carrier.expense.data.currency;
                    this.expense.payment_method = this.carrier.expense.data.payment_method;
                    this.expense.description = this.carrier.expense.data.description;

                    this.lines = [];
                    this.carrier.expense.data.lines.forEach(line => this.lines.push(Object.assign({}, line)));
                } else {
                    this.expense.taxable_fulfillment_due = this.formatDateYMD(this.contract.attributes.unloadings[this.contract.attributes.unloadings.length - 1].time_to);
                    this.expense.currency = this.carrier.price_currency;
                    this.expense.document_type = 'invoice';
                    this.expense.payment_method = 'bank';
                }

                if (this.lines.length <= 0) {
                    this.addLine();
                }
            },
        },

        methods: {
            addLine() {
                const loading = this.contract.attributes.loadings[0];
                const unloading = this.contract.attributes.unloadings[this.contract.attributes.unloadings.length - 1];
                this.lines.push({
                    name: `${this.carrier.order_number}, ${loading.place.city}, ${unloading.place.city}`,
                    quantity: 1,
                    vat_rate: 21,
                    unit_price: this.carrier.price,
                });
            },

            removeLine(idx) {
                if (this.lines[idx].id) return;

                this.lines.splice(idx, 1);
            },

            loadIsdoc(e) {
                this.loadingIsdoc = true;
                if (this.dragEnter) {
                    this.dragEnter = false;
                    let dt = e.dataTransfer;
                    let files = dt.files;
                    const isdoc_file = [...files][0];
                    Expense.fetchIsdoc(isdoc_file)
                        .then((data) => {
                            this.mergeIsdoc(isdoc_file, data);
                        })
                        .finally(() => {
                            this.loadingIsdoc = false;
                        });
                } else {
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.onchange = () => {
                        const isdoc_file = input.files[0];
                        Expense.fetchIsdoc(isdoc_file)
                            .then((data) => {
                                this.mergeIsdoc(isdoc_file, data);
                            })
                            .finally(() => {
                                this.loadingIsdoc = false;
                            });
                    };
                    input.click();
                }
            },

            mergeIsdoc(isdoc_file, data) {
                if (data) {
                    this.expense.due_on = data.due_on;
                    this.expense.original_number = data.original_number;
                    this.expense.variable_symbol = data.variable_symbol;
                    this.expense.issued_on = data.issued_on;
                    this.expense.taxable_fulfillment_due = data.taxable_fulfillment_due;
                    this.expense.currency = data.currency;
                    this.expense.description = data.description;

                    this.lines = [];
                    data.lines.forEach(line => this.lines.push(Object.assign({}, line)));
                } else {
                    alert('Soubor není ISDOC.');
                }

                this.newAttachment = isdoc_file;
                this.attachmentToExpense();
            },

            addNewAttachment(e) {
                if (this.dragEnter) {
                    this.dragEnter = false;
                    let dt = e.dataTransfer;
                    let files = dt.files;
                    this.newAttachment = [...files][0];
                    this.attachmentToExpense();
                } else {
                    let input = document.createElement('input');
                    input.type = 'file';
                    input.onchange = () => {
                        this.newAttachment = input.files[0];
                        this.attachmentToExpense();
                    };
                    input.click();
                }
            },

            attachmentToExpense() {
                if (this.newAttachment) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      this.expense.attachment = e.target.result;
                    };
                    reader.readAsDataURL(this.newAttachment);
                } else {
                    this.expense.attachment = null;
                }
            },

            deleteNewAttachment() {
                this.newAttachment = null;
                this.attachmentToExpense();
            },

            createExpense() {
                this.creating_invoice = true;

                let data = JSON.parse(JSON.stringify(this.expense));
                data.subject_id = this.client.attributes.invoicing_fakturoid_id;
                data.lines = this.lines;
                data.lines.forEach((line) => {
                    line.unit_price = this.priceReplaceCommaWithDot(line.unit_price);
                });

                let expense = null;
                if (this.carrier.expense) {
                    expense = new Expense({
                        id: this.carrier.expense.id,
                        carrier_contract_id: this.carrier.id,
                        attachment: this.newAttachment,
                        data
                    });
                } else {
                    expense = new Expense({
                        carrier_contract_id: this.carrier.id,
                        attachment: this.newAttachment,
                        data
                    });
                }

                expense.save(true)
                    .then((e) => {
                        this.carrier.expense = e.attributes;
                        this.$emit('expense-created', e);
                        this.formDialog = false;
                    })
                    .finally(() => {
                        this.creating_invoice = false;
                    });
            },

            registerClient(client) {
                this.registering_client = true;
                client.registerInFakturoid('supplier').finally(() => {
                    this.registering_client = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>

