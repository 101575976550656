<template>

    <div>
        <v-row>
            <v-col cols="12" class="px-0">{{ label }}</v-col>
        </v-row>
        <v-row>
            <v-col class="px-0">
                <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="dateFormatted"
                                prepend-icon="mdi-calendar"
                                v-on="on"
                                dense outlined readonly
                                class="no-input-margin"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="date"
                            no-title
                            @input="menu1 = false"
                            locale="cs-CZ"
                            :first-day-of-week="1"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col v-if="usetime" class="px-0">
                <v-text-field
                    v-model="time"
                    @change="formatTime"
                    @blur="emitInput"
                    dense outlined
                    class="no-input-margin"
                ></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import moment from 'moment';
    import ErrorHandler from "../ErrorHandler";
    import date from "../mixins/date";

    export default {
        name: "DateTimePicker",
        props: {
            datetime: { default: null },
            resettime: { default: false },
            usetime: { default: true },
            label: {}
        },

        data: function () {
            return {
                menu1: null,

                date: null,
                time: null,
                dateFormatted: null,
                timeFormatted: null
            };
        },

        created() {
            let datetime = this.datetime;
            if (!datetime) {
                if (this.resettime) {
                    datetime = new Date(moment().format('YYYY-MM-DD 00:00'));
                } else {
                    datetime = new Date();
                }
            }
            this.assignDateTime(datetime);
        },

        watch: {
            date(val) {
                this.dateFormatted = this.formatDate(val);
                this.emitInput();
            },

            datetime(val) {
                this.assignDateTime(val);
            }
        },

        methods: {
            assignDateTime(datetime) {
                let moment_datetime = moment(datetime);
                this.date = moment_datetime.format('YYYY-MM-DD');
                this.time = moment_datetime.format('HH:mm');
            },

            emitInput() {
                this.menu1 = false;
                try {
                    this.date = this.parseDate(this.dateFormatted);
                    let iso_datetime = '';
                    if (this.usetime) {
                        this.time = this.parseTime(this.time);
                        iso_datetime = new Date(`${this.date} ${this.time}`).toISOString();
                    } else {
                        iso_datetime = this.date;
                    }
                    console.log(iso_datetime);
                    this.$emit('input', iso_datetime);
                } catch(e) {
                    ErrorHandler.raiseError('Datum nebo čas není ve správném formátu.', e);
                }
            },

            formatDate(date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}. ${month}. ${year}`;
            },

            formatTime(time) {
                if (time.indexOf(':') === -1 && time.length === 4) {
                    this.time = time.replace(/^(\d\d)(\d\d)$/, '$1:$2');
                }
            },

            parseDate(date) {
                if (!date) return null;

                const [day, month, year] = date.split(/\. */);
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            },

            parseTime(time) {
                let t = time;

                if (t.indexOf(',') >= 0) {
                    const [hour, minute] = t.split(/,/);
                    t = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
                }

                return t;
            },
        }
    }
</script>

<style lang="scss">
    .v-picker__body {
        margin: initial;
    }

    .no-input-margin .v-input__slot {
        padding: 0px 4px 0px 4px !important;
    }
</style>
