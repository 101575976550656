<template>
    <v-textarea
            v-model="model"
            :label="label"
            outlined
            :prepend-icon="prependIcon"
            :background-color="backgroundColor"
            auto-grow
            :rows="2"
    ></v-textarea>
</template>

<script>
    export default {
        name: "TextArea",
        props: ['value', 'label', 'visible'],

        data: function() {
            return {
                model: ''
            };
        },

        created() {
            this.model = this.value;
        },

        computed: {
            prependIcon() {
                return this.visible ? 'mdi-eye' : undefined;
            },

            backgroundColor() {
                return this.visible ? (this.$vuetify.theme.dark ? '#982222' : '#ffcccc') : undefined;
            }
        },

        watch: {
            value(val) {
                this.model = this.value;
            },

            model(val) {
                this.$emit('input', val);
            }
        }
    }
</script>

<style scoped>

</style>