<template>
    <div>
        <v-autocomplete
                v-model="partner"
                :items="entries"
                :loading="isLoading"
                :search-input.sync="searchText"
                :label="label"
                item-text="name"
                item-value="id"
                placeholder="Začněte psát..."
                clearable
                return-object
                no-filter
                outlined
                hide-details
                dense
        ></v-autocomplete>
        <div class="mb-5">
            <slot :item="partner"></slot>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import Utils from "../Utils";

    export default {
        name: "InfoAutocomplete",

        props: ['value', 'url', 'label', 'params'],

        data: () => {
            return {
                partner: null,
                isLoading: false,
                entries: [],
                searchText: '',
                lastSearchText: '',
                doSearchRunning: false,
                performSearch: true
            };
        },

        beforeMount() {
            this.performSearch = false;
            if (this.value) {
                this.entries = [ this.value ];
                this.partner = this.value;
            }
        },

        watch: {
            partner(value) {
                this.$emit('input', value);
            },

            value(val) {
                this.performSearch = false;
                if (val) {
                    this.entries = [val];
                } else {
                    this.entries = [];
                }
                this.partner = val;
            },

            searchText(value) {
                if (this.performSearch && !this.doSearchRunning) {
                    this.doSearch();
                }
                this.performSearch = true;
            }
        },

        methods: {
            searchPartner(value) {
                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                let params = Object.assign({}, this.params);
                params.token = value;
                axios.get(Utils.buildUrl([this.url]), { params: params })
                    .then(res => {
                        this.entries = res.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (this.isLoading = false))
            },

            doSearch() {
                if (this.lastSearchText === this.searchText) {
                    this.doSearchRunning = false;
                    this.searchPartner(this.searchText)
                } else {
                    this.doSearchRunning = true;
                    this.lastSearchText = this.searchText;
                    setTimeout(() => {
                        this.doSearch();
                    }, 500);
                }
            }
        }
    }
</script>

<style scoped>

</style>