<template>
    <v-card text>
        <v-card-text>
            <v-container fluid >
                <v-row wrap>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.ico" label="IČO"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.dic" label="DIČ"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.email" label="e-mail"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.fax" label="Fax"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.mob" label="Mobil"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.tel1" label="Telefon 1"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field v-model="editedItem.tel2" label="Telefon 2"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <v-tabs>
                <v-tab key="cs">Česky</v-tab>
                <v-tab key="en">Anglicky</v-tab>

                <v-tab-item v-for="lang in ['cs', 'en']" :key="lang">
                    <v-card text>
                        <v-card-text>
                            <v-row wrap>
                                <v-col cols="12" md="6">
                                    <TextArea v-model="editedItem.hq[lang]" label="Sídlo"></TextArea>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <TextArea v-model="editedItem.office[lang]" label="Provozovna"></TextArea>
                                </v-col>
                            </v-row>
                            <v-row wrap>
                                <v-col cols="12">
                                    <TextArea v-model="editedItem.or[lang]" label="Obchodní rejstřík"></TextArea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

            </v-tabs>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="saveSetup">Uložit</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import setup from '../mixins/setup';
    import TextArea from "./TextArea";

    export default {
        name: "SetupCompany",
        components: {TextArea},
        mixins: [setup],

        data: () => {
            return {
                defaultItem: {
                    hq: { cs: '', en: '' },
                    office: { cs: '', en: '' },
                    or: { cs: '', en: '' }
                },

                url: '/api/setup/company'
            };
        }
    }
</script>

<style scoped>

</style>