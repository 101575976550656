import Contract from "../models/Contract";
import Collection from "../Collection";

export default class Contracts extends Collection {
    get url() {
        return '/api/contracts';
    }

    get model() {
        return Contract;
    }
}