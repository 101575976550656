<template>
    <v-card v-if="partner" text>
        <v-card-title>
            <h1>{{ partner.attributes.name }}</h1>
            <v-chip v-for="tag in partner.attributes.tags" outlined class="ml-2">{{ tag }}</v-chip>
            <v-spacer></v-spacer>
            Poslední aktualizace: {{ (new Date(partner.attributes.updated_at)).toLocaleString() }}
            <v-spacer></v-spacer>
            <v-icon class="mr-2" @click="dialog = true">mdi-pencil</v-icon>
            <v-icon @click="deletePartner()">mdi-delete</v-icon>
            <PartnerFormDialog v-model="partner" :dialog="dialog" :new-enabled="false"
                                @open="dialog = true" @close="dialog = false" @saved="dialog = false"></PartnerFormDialog>
        </v-card-title>
        <v-card-text>
            <v-row row wrap>
                <v-col cols="12" sm="4" pt-5>
                    <table class="partner-table">
                        <tr><th>Ulice: </th><td>{{ partner.attributes.street }}</td></tr>
                        <tr><th>Město: </th><td>{{ partner.attributes.city }}</td></tr>
                        <tr><th>PSČ: </th><td>{{ partner.attributes.zip }}</td></tr>
                        <tr><th>Země: </th><td>{{ partner.attributes.country }}</td></tr>
                    </table>
                </v-col>
                <v-col cols="12" sm="4" pt-5>
                    <table class="partner-table">
                        <tr><th>ICO: </th><td>{{ partner.attributes.ico }}</td></tr>
                        <tr><th>DIC: </th><td>{{ partner.attributes.dic }}</td></tr>
                    </table>
                </v-col>
                <v-col cols="12" sm="4" pt-5>
                    <table class="partner-table">
                        <tr><th>Raal: </th><td>{{ partner.attributes.raal }}</td></tr>
                        <tr><th>Timocom: </th><td>{{ partner.attributes.timocom }}</td></tr>
                    </table>
                </v-col>
            </v-row>
            <v-tabs v-model="activeTab">
                <v-tab key="address">Kontakt</v-tab>
                <v-tab key="contacts">Osoby</v-tab>
                <v-tab key="contracts-client">Zakázky jako zákazník</v-tab>
                <v-tab key="contracts-carrier">Zakázky jako dopravce</v-tab>
                <v-tab key="notes" :class="{ 'not-empty': partner.attributes.notes && partner.attributes.notes.length > 0 }">Poznámky</v-tab>
                <v-tab key="invoicing">Fakturace</v-tab>
                <v-tab key="documents">Dokumenty</v-tab>

                <v-tab-item key="address">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="12" md="6">
                                    <h3>Adresa sídla</h3>
                                    <table class="partner-table">
                                        <tr><th>Fakturace?: </th><td>{{ invoicingHeadquartersAddress }}</td></tr>
                                        <tr><th>Ulice: </th><td>{{ partner.attributes.street }}</td></tr>
                                        <tr><th>Město: </th><td>{{ partner.attributes.city }}</td></tr>
                                        <tr><th>PSČ: </th><td>{{ partner.attributes.zip }}</td></tr>
                                        <tr><th>Země: </th><td>{{ partner.attributes.country }}</td></tr>
                                    </table>

                                    <h3 class="mt-5">Adresa provozovny</h3>
                                    <table v-for="(address, idx) in partner.attributes.addresses" class="partner-table mb-5">
                                        <tr><th>Fakturace?: </th><td>{{ address.invoicing ? 'Ano' : 'Ne' }}</td></tr>
                                        <tr><th>Ulice: </th><td>{{ address.street }}</td></tr>
                                        <tr><th>Město: </th><td>{{ address.city }}</td></tr>
                                        <tr><th>PSČ: </th><td>{{ address.zip }}</td></tr>
                                        <tr><th>Země: </th><td>{{ address.country }}</td></tr>
                                    </table>

                                    <h3>Kontaktní údaje</h3>
                                    <table class="partner-table">
                                        <tr><th>Telefon: </th><td><PhoneInfo :phone="partner.attributes.phone"></PhoneInfo></td></tr>
                                        <tr><th>Fax: </th><td>{{ partner.attributes.fax }}</td></tr>
                                        <tr><th>Email: </th><td><EmailInfo :email="partner.attributes.email"></EmailInfo></td></tr>
                                    </table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="contacts">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="12">
                                    <Contacts :partner="partner.attributes" :show-partner="false"></Contacts>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="contracts-client">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="12">
                                    <Contracts :client="partner.attributes" label="Zákaznické zakázky" :save-search="false"></Contracts>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="contracts-carrier">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="12">
                                    <Contracts :carrier="partner.attributes" label="Zakázky jako dopravce" :new-enabled="false" :no-carrier="false" :save-search="false"></Contracts>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="notes">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="12">
                                    <pre style="white-space: break-spaces;">{{ partner.attributes.notes }}</pre>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="invoicing">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="6">
                                    <h1 class="mt-3 mb-3">Fakturační adresa</h1>
                                    <table class="partner-table mb-5">
                                        <tr><th>Ulice: </th><td>{{ invoicingAddress.street }}</td></tr>
                                        <tr><th>Město: </th><td>{{ invoicingAddress.city }}</td></tr>
                                        <tr><th>PSČ: </th><td>{{ invoicingAddress.zip }}</td></tr>
                                        <tr><th>Země: </th><td>{{ invoicingAddress.country }}</td></tr>
                                    </table>
                                </v-col>
                                <v-col cols="6">
                                    <h1 class="mt-3 mb-3">Fakturoid</h1>
                                    <table class="partner-table mb-5">
                                        <tr><th>Splatnost:</th><td>{{ partner.attributes.invoicing_due }}</td></tr>
                                        <tr><th>Šablona:</th><td>{{ partner.attributes.invoicing_template_name }}</td></tr>
                                        <tr>
                                            <th>Fakturoid Id:</th>
                                            <td v-if="partner.attributes.invoicing_fakturoid_id">
                                                {{ partner.attributes.invoicing_fakturoid_id }} ({{ partner.attributes.invoicing_fakturoid_type }})
                                            </td>
                                            <td v-else>
                                                <v-select v-model="fakturoid_type"
                                                          item-text="title" item-value="value"
                                                          :items="[
                                                                      { value: 'customer', title: 'Zákazník'},
                                                                      { value: 'supplier', title: 'Dopravce'},
                                                                      { value: 'both', title: 'Obojí'}
                                                                  ]"
                                                          :error-messages="fakturoid_type_error"
                                                          label="Typ"></v-select>
                                                <v-btn color="primary" dark class="mb-2" :disabled="registering_client" @click.prevent="registerClient(partner)">Registorvat ve fakturoidu</v-btn>
                                            </td>
                                        </tr>
                                        <tr><th>Způsob odesílání faktur:</th><td>{{ partner.attributes.invoicing_sending_notes }}</td></tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-row wrap>
                                <v-col cols="12">
                                    <Contacts :partner="partner.attributes" :show-partner="false" invoicing></Contacts>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>

                <v-tab-item key="documents">
                    <v-card>
                        <v-container fluid>
                            <v-row wrap>
                                <v-col cols="12">
                                    <Invoices :client="partner.attributes" label="Faktury"></Invoices>
                                    <Documents :client="partner.attributes" label="Dokumenty jako zákazník"></Documents>
                                    <Documents :carrier="partner.attributes" label="Dokumenty jako dopravce" class="mt-5"></Documents>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template>

<script>
    import Partner from "../models/Partner";
    import Contacts from "./Contacts";
    import Contracts from "./Contracts";
    import PartnerFormDialog from "./PartnerFormDialog";
    import EmailInfo from "./EmailInfo";
    import PhoneInfo from "./PhoneInfo";
    import Orders from "./Orders.vue";
    import Documents from "./Documents.vue";
    import Invoices from "./Invoices.vue";

    export default {
        name: "PartnerView",

        props: {
            item: {Contacts},
        },

        components: {Invoices, Documents, Orders, PhoneInfo, EmailInfo, PartnerFormDialog, Contracts, Contacts},

        data: () => {
            return {
                dialog: false,
                partner: null,
                activeTab: null,
                fakturoid_type: null,
                fakturoid_type_error: null,
                registering_client: false,
            };
        },

        beforeMount() {
            this.partner = this.item;
        },

        mounted() {
            this.$nextTick(function () {
                if (!this.partner) {
                    let partner = new Partner({id: this.$route.params.id});
                    partner.fetch().then(() => {
                        this.partner = partner;
                    });
                }
            });
        },

        computed: {
            invoicingHeadquartersAddress() {
                if (!this.partner) return 'Ano';

                const invoicingAddresses = this.partner.attributes.addresses.filter(address => address.invoicing);
                if (invoicingAddresses.length > 0) {
                    return 'Ne';
                }

                return 'Ano';
            },

            invoicingAddress() {
                if (!this.partner) return {};

                return this.partner.invoicingAddress();
            },
        },

        methods: {
            deletePartner() {
                if (confirm('Opravdu smazat partnera?')) {
                    this.partner.delete()
                        .then(() => {
                            this.$router.push({ name: 'partners' });
                        });
                }
            },

            registerClient(client) {
                if (!this.fakturoid_type) {
                    this.fakturoid_type_error = 'Vyberte typ';
                    return;
                }
                this.registering_client = true;
                client.registerInFakturoid(this.fakturoid_type).finally(() => {
                    this.registering_client = false;
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .partner-table {
        th {
            text-align: left;
        }
    }

    .not-empty {
      text-decoration: underline;
      text-decoration-color: red;
      text-decoration-thickness: 5px;
    }
</style>
