import User from "../models/User";
import Collection from "../Collection";

export default class Users extends Collection {
    get url() {
        return '/api/users';
    }

    get model() {
        return User;
    }
}