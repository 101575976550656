import Invoice from "../models/Invoice";
import Collection from "../Collection";

export default class Invoices extends Collection {
    get url() {
        return '/api/invoices';
    }

    get model() {
        return Invoice;
    }
}
