<template>
    <v-app :style="appStyle">
        <v-app-bar v-if="logged" app clipped-left>
            <v-menu class="hidden-sm-and-up">
                <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
                </template>
                <v-list>
                    <v-list-item v-for="link in links">
                        <v-list-item-title v-if="link.permitted">
                            <router-link :to="link.to">{{ link.title }}</router-link>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-toolbar-items class="hidden-xs-only">
                <v-btn v-for="link in links" v-if="link.permitted" :to="link.to" text>{{ link.title }}</v-btn>
            </v-toolbar-items>

            <v-spacer></v-spacer>

            <v-toolbar-items>
                <v-icon @click="switchThemes()" class="mr-2">mdi-brightness-6</v-icon>
            </v-toolbar-items>

            <v-menu
                    v-if="messages.length > 0"
                    v-model="messageMenu"
                    :close-on-content-click="false"
                    :nudge-width="400"
                    :max-height="200"
                    offset-x
            >
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mr-2">mdi-email-outline</v-icon>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item v-for="message in messages">
                            <v-list-item-action>
                                <v-icon @click="destroyMessage(message)">mdi-cancel</v-icon>
                            </v-list-item-action>
                            <v-list-item-title><router-link :to="buildUrl([message.attributes.link])">{{ message.attributes.title }}</router-link></v-list-item-title>
                            <v-list-item-content>{{ message.attributes.body }}</v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn text @click="messageMenu = false">Zavřít</v-btn>
                        <v-btn color="primary" text @click="destroyAllMessages()">Smazat vše</v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>

            <router-link v-if="isPagePermitted('setup')" :to="buildUrl(['setup', 'company'])"><v-icon class="px-3">mdi-format-align-left</v-icon></router-link>

            <v-btn v-if="logged" :to="editUser" text>{{ user.name }}</v-btn>
            <v-toolbar-items v-if="logged">
                <v-btn text @click="logout">Odhlásit</v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <notifications group="messages" :close-on-click="true" :max="3">
            <template slot="body" slot-scope="props">
                <v-card class="notification" text @click="props.close">
                    <v-card-text>{{ props.item.title }}</v-card-text>
                </v-card>
            </template>
        </notifications>
        <notifications group="errors" :close-on-click="true"></notifications>

        <v-main>
            <v-container fluid>
                <router-view v-if="logged"/>
                <Login v-else></Login>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Login from './components/Login';
    import axios from 'axios';
    import MessagesCollection from './collections/Messages';
    import Message from './models/Message';
    import Utils from "./Utils";
    import utils from "./mixins/utils";
    import permissions from "./mixins/permissions";

    export default {
        mixins: [utils, permissions],
        components: {Login},

        data: function () {
            return {
                logged: window.logged,

                messageMenu: false,
                messages: [],
                newMessage: false
            }
        },

        mounted: function () {
            this.$root.$on('reload-page', () => {
                if (window.reload_possible) {
                    window.location.reload();
                } else {
                    let note = {
                        group: 'messages',
                        duration: 10000,
                        title: 'Nová verze aplikace je k dispozici: stiskněte CTRL-R nebo F5'
                    };
                    this.$notify(note);
                }
            });
            this.$root.$on('user-logged', (user) => {
                this.logged = true;
                this.user = user;
                window.logged = true;
                window.current_user = user;
                window.consumer.connect();
                if (this.$route.path === '/') {
                    this.$router.push(Utils.buildUrl(['contracts']));
                }
                if (Notification.permission === "granted") {
                } else if (Notification.permission !== "denied") {
                    Notification.requestPermission();
                }
            });
            this.$root.$on('new-message', (data) => {
                this.dispatchMessage(data);
            });
            this.$root.$on('error', (message, error) => {
                let note = {
                    group: 'errors',
                    duration: -1,
                    type: 'error',
                    title: message
                };
                this.$notify(note);
            });
            this.$nextTick(function () {
                if (this.logged) {
                    this.fetchMessages();
                }
                this.checkVersion();
            });
        },

        computed: {
            appStyle() {
                let bgColor = this.$vuetify.theme.dark ? '#292929' : '#fff';
                return { 'background-color': bgColor };
            },

            links() {
                return [
                    {'to': Utils.buildUrl(['contracts']), 'title': 'Zakázky', permitted: this.isPagePermitted('contracts') },
                    {'to': Utils.buildUrl(['partners']), 'title': 'Partneři', permitted: this.isPagePermitted('partners')},
                    {'to': Utils.buildUrl(['contacts']), 'title': 'Kontaktní osoby', permitted: this.isPagePermitted('contacts')},
                    {'to': Utils.buildUrl(['places']), 'title': 'Místa', permitted: this.isPagePermitted('places')},
                    {'to': Utils.buildUrl(['reports', 'expenses']), 'title': 'Reporty', permitted: this.isPagePermitted('reports')},
                    {'to': Utils.buildUrl(['users']), 'title': 'Uživatelé', permitted: this.isPagePermitted('users')},
                    {'to': Utils.buildUrl(['orders']), 'title': 'Historie objednávek', permitted: this.isPagePermitted('orders')},
                    {'to': Utils.buildUrl(['invoices']), 'title': 'Faktury', permitted: this.isPagePermitted('invoices')},
                    {'to': Utils.buildUrl(['expenses']), 'title': 'Náklady', permitted: this.isPagePermitted('expenses')},
                ];
            },

            editUser() {
                return Utils.buildUrl(['users', this.user.id, 'edit']);
            },
        },

        methods: {
            switchThemes() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                window.localStorage.setItem('vuetify-theme-dark', JSON.stringify(this.$vuetify.theme.dark));
            },

            logout() {
                axios.get(Utils.buildUrl(['api', 'users', 'logout']))
                    .then((response) => {
                        this.logged = false;
                        this.user = {};
                        window.logged = false;
                        window.current_user = {};
                        window.consumer.disconnect();
                    });
            },

            dispatchMessage(data) {
                if (data.kind === 'contract:created') {
                    this.acceptNewMessage(data);
                } else{
                    this.$root.$emit(data.kind, data);
                }
            },

            acceptNewMessage(data) {
                let message = new Message(data);
                this.messages.unshift(message);
                let note = {
                    group: 'messages',
                    duration: 10000
                };
                note.title = data.title;
                this.$notify(note);
                if (Notification.permission === "granted") {
                    new Notification(data.title);
                }
            },

            destroyMessage(message) {
                message.delete().then(() => {
                    let idx = this.messages.indexOf(message);
                    this.messages.splice(idx, 1);
                    if (this.messages.length <= 0) {
                        this.messageMenu = false;
                    }
                });
            },

            destroyAllMessages() {
                this.messages.forEach((message) => {
                    this.destroyMessage(message);
                });
            },

            fetchMessages() {
                if (this.logged === true) {
                    let messages = new MessagesCollection();
                    messages.fetch({}, { notifications: false }).then((data) => {
                        this.messages = data.models;
                    });
                }

                window.setTimeout(() => this.fetchMessages(), 30000);
            },

            checkVersion() {
                axios.get('/version').then((response) => {
                    let vueapp_js = response.data.vueapp_js;
                    let vueapp_css = response.data.vueapp_css;
                    let reload = false;
                    if (vueapp_js) {
                        vueapp_js.forEach((path) => {
                            let el = document.querySelector('script[src="' + path + '"]');
                            if (! el) {
                                reload = true;
                            }
                        });
                    }
                    if (vueapp_css) {
                        vueapp_css.forEach((path) => {
                           let el = document.querySelector('link[href="' + path + '"]');
                           if (! el) {
                               reload = true;
                           }
                        });
                    }
                    if (reload) {
                        this.$root.$emit('reload-page');
                    }
                });

                window.setTimeout(() => this.checkVersion(), 60000);
            }
        }
    }
</script>

<style lang="scss">
    .notification {
        background-color: greenyellow !important;
        border: 1px solid lightgrey !important;
    }
</style>
