<template>
    <v-dialog v-model="formDialog" content-class="contract-form-dialog" persistent @keydown="handleEsc">
        <template v-if="newEnabled" v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nová zakázka</v-btn>
        </template>
        <v-card class="contract-form-dialog-content">
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        <span class="headline">{{ formTitle }}</span>
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-icon v-if="activeTab > 0" @click="prevTab">mdi-arrow-left</v-icon>
                        <v-icon v-if="activeTab < (tabs.length - 1)" @click="nextTab">mdi-arrow-right</v-icon>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text class="grey-background">
                <v-tabs v-model="activeTab">
                    <v-tab v-for="tab in tabs" :key="tab.id" :class="{right: isRightTab(tab.id)}">{{ tab.title }}</v-tab>

                    <v-tab-item key="contract">
                        <v-card>
                            <v-container fluid>
                                <v-row wrap>
                                    <v-col cols="12" md="6">
                                        <v-card min-height="450">
                                            <v-container fluid >
                                                <v-row wrap>
                                                    <v-col cols="8">
                                                        <InfoAutocomplete v-model="selectedClient" url="/api/partners/search" label="Zákazník" v-slot="props">
                                                            <PartnerInfo :item="props.item"></PartnerInfo>
                                                        </InfoAutocomplete>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <PartnerFormDialog v-model="newClientItem" :dialog="newClientDialog" @open="newClientDialog = true" @close="newClientDialog = false" @saved="newClientUpdateItem"></PartnerFormDialog>
                                                        <a v-if="selectedClient && selectedClient.id"
                                                           :href="buildUrl(['partners', selectedClient.id])"
                                                           target="_blank"
                                                        ><v-btn color="primary" dark class="mb-2"><v-icon>mdi-open-in-new</v-icon>PODROBNOSTI</v-btn></a>
    <!--                                                    <PartnerFormDialog v-if="editClientItem" v-model="editClientItem"-->
    <!--                                                                       :dialog="editClientDialog" @open="editClientDialog = true" @close="editClientDialog = false" @saved="editClientUpdateItem" new-label="Upravit partnera"></PartnerFormDialog>-->
                                                    </v-col>
                                                    <v-col cols="8" v-if="selectedClient">
                                                        <InfoSelect v-model="selectedClientContact" url="/api/contacts/search" label="Zákazníkova kontaktní osoba"
                                                                    :params="clientContactParams" :use-first="true" v-slot="props">
                                                            <ContactInfo :item="props.item"></ContactInfo>
                                                        </InfoSelect>
                                                    </v-col>
                                                    <v-col cols="4" v-if="selectedClient">
                                                        <ContactFormDialog :partner="selectedClient" v-model="newContactItem"
                                                                           :dialog="newContactDialog" @open="newContactDialog = true" @close="newContactDialog = false"
                                                                           @saved="newContactUpdateItem"></ContactFormDialog>
    <!--                                                    <ContactFormDialog v-if="editContactItem" :partner="selectedClient" v-model="editContactItem"-->
    <!--                                                                       :dialog="editContactDialog" @open="editContactDialog = true" @close="editContactDialog = false"-->
    <!--                                                                       @saved="editContactUpdateItem" new-label="Upravit kontakt"></ContactFormDialog>-->
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <TextArea v-model="editedItem.client_notes" label="Poznámky k zákazníkovi"></TextArea>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12" md="6">
                                        <v-card min-height="450">
                                            <v-container fluid >
                                                <v-row wrap>
                                                    <v-col cols="12">
                                                        <TextField v-model="editedItem.number" label="Číslo zakázky"></TextField>
                                                    </v-col>
<!--                                                    <v-col cols="12">-->
<!--                                                        <TextField v-model="editedItem.due_days" label="Splatnost ve dnech"></TextField>-->
<!--                                                    </v-col>-->
                                                    <v-col cols="4">
                                                        <TextField v-model="editedItem.price" label="Cena"></TextField>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        <v-select v-model="editedItem.price_currency" :items="currencyList" label="Měna" solo></v-select>
                                                    </v-col>
                                                    <v-col cols="4">
                                                        Cena v CZK: {{ editedItem.price_czk }}
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <TextField v-model="editedItem.price_notes" label="Poznámky k ceně"></TextField>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>
                                    </v-col>

                                    <v-col cols="12">
                                        <TextArea v-model="editedItem.goods" label="Zboží" :visible="true"></TextArea>
                                    </v-col>
                                    <v-col cols="12">
                                        <TextArea v-model="editedItem.notes" label="Poznámky k zakázce"></TextArea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item key="loading">
                        <v-container fluid >
                            <v-row wrap>
                                <v-col v-for="(loading, idx) in editedItem.loadings"
                                       cols="12" md="6">
                                    <ContractPlaceForm
                                            v-model="editedItem.loadings[idx]"
                                            :idx="idx"
                                            label="Nakládka"
                                            notes-label="Poznámky k nakládce"
                                            time-from-label="Čas nakládky od"
                                            time-to-label="Čas nakládky do"
                                            time-notes-label="Poznámky k času nakládky"
                                            @remove-place="removeLoading"
                                    ></ContractPlaceForm>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" dark @click.prevent="addNewLoading">Přidat nakládku</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item key="unloading">
                        <v-container fluid >
                            <v-row wrap>
                                <v-col v-for="(unloading, idx) in editedItem.unloadings" cols="12" md="6">
                                    <ContractPlaceForm
                                            v-model="editedItem.unloadings[idx]"
                                            :idx="idx"
                                            label="Vykládka"
                                            notes-label="Poznámky k vykládce"
                                            time-from-label="Čas vykládky od"
                                            time-to-label="Čas vykládky do"
                                            time-notes-label="Poznámky k času vykládky"
                                            @remove-place="removeUnloading"
                                    ></ContractPlaceForm>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" dark @click.prevent="addNewUnloading">Přidat vykládku</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item key="carriers">
                        <v-container fluid >
                            <v-row wrap>
                                <v-col v-for="(carrier, idx) in editedItem.carrier_contracts" cols="12" md="6">
                                    <CarrierContract v-model="editedItem.carrier_contracts[idx]"
                                                     :idx="idx"
                                                     :contract-item="editedItem"
                                                     @save="save(false)"
                                                     @reload-contract="reloadContract"
                                                     @remove-carrier="removeCarrier"
                                    ></CarrierContract>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" dark @click.prevent="addNewCarrier">Přidat Dopravce</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item key="documents">
                        <v-container fluid>
                            <v-row wrap @drop.prevent="addNewDocuments" @dragenter.prevent="dragEnter = true" @dragover.prevent="dragEnter = true" @dragleave.prevent="dragEnter = false" @dragend.prevent="dragEnter = false" :class="{ dragging: dragEnter }">
                                <v-col v-if="editedItem.documents && editedItem.documents.length > 0">
                                    <b>Nahrané dokumenty</b>
                                    <br />
                                    <DocumentLinkList :documents="editedItem.documents"></DocumentLinkList>
                                </v-col>
                                <v-col v-if="newDocuments.length > 0" cols="12">
                                    <b>Nově přidané dokumenty</b>
                                    <br />
                                    <div v-for="(file, idx) in newDocuments">
                                        <v-icon @click="deleteNewDocument(idx)" small>mdi-delete</v-icon>
                                        {{ file.name }} ({{ (file.size / 1024 / 1024).toFixed(4) }} MB)
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn color="primary" dark @click.prevent="addNewDocument">Přidat dokument</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>

            <v-card-actions>
                <v-row>
                    <v-col cols="4">
                    </v-col>
                    <v-col cols="4" class="text-center">
                        <v-icon v-if="activeTab > 0" @click="prevTab">mdi-arrow-left</v-icon>
                        <v-icon v-if="activeTab < (tabs.length - 1)" @click="nextTab">mdi-arrow-right</v-icon>
                    </v-col>
                    <v-col cols="4" class="text-right">
                        <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                        <v-btn v-if="editedItem.state !== 'closed'" color="blue darken-1" text @click="saveAndUpload" :loading="isSaving">Uložit a zavřít</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Contract from "../models/Contract";
    import editableDialog from "../mixins/editableDialog";
    import currency from '../mixins/currency';
    import InfoAutocomplete from './InfoAutocomplete';
    import PartnerInfo from './PartnerInfo';
    import PlaceInfo from './PlaceInfo';
    import ContactInfo from './ContactInfo';
    import CarrierContract from './CarrierContract';
    import PartnerFormDialog from "./PartnerFormDialog";
    import ContactFormDialog from "./ContactFormDialog";
    import PlaceFormDialog from "./PlaceFormDialog";
    import ContractPlaceForm from "./ContractPlaceForm";
    import InfoSelect from "./InfoSelect";
    import Partner from "../models/Partner";
    import Contact from "../models/Contact";
    import TextArea from "./TextArea";
    import utils from "../mixins/utils";
    import Utils from "../Utils";
    import axios from "axios";
    import TextField from "./TextField";
    import DocumentLinkList from "./DocumentLinkList";

    export default {
        name: "ContractFormDialog",
        mixins: [editableDialog, currency, utils],
        components: {
            DocumentLinkList,
            TextField,
            TextArea,
            InfoSelect,
            ContractPlaceForm,
            PlaceFormDialog,
            ContactFormDialog,
            PartnerFormDialog, InfoAutocomplete, PartnerInfo, PlaceInfo, ContactInfo, CarrierContract},

        props: {
            client: {},
            carrier: {},
            newEnabled: { default: true }
        },

        data: () => {
            return {
                itemClass: Contract,

                activeTab: null,
                tabs: [
                    { id: 'contract', title: 'Zakázka' },
                    { id: 'loading', title: 'Nakládka' },
                    { id: 'unloading', title: 'Vykládka' },
                    { id: 'carriers', title: 'Dopravci + Objednávky' },
                    { id: 'documents', title: 'Dokumenty' },
                ],

                selectedClient: null,
                selectedClientContact: null,
                clientContactParams: { partner_id: null },

                newClientItem: null,
                newClientDialog: false,

                editClientItem: null,
                editClientDialog: false,

                newContactItem: null,
                newContactDialog: false,

                editContactItem: null,
                editContactDialog: false,

                newUnloadingItem: null,
                newUnloadingDialog: false,

                defaultItem: {
                    number: '',
                    due_days: '',
                    price: '',
                    price_czk: '',
                    price_currency: 'CZK',
                    price_notes: '',
                    client_notes: '',
                    loadings: [],
                    unloadings: [],
                    notes: '',
                    carrier_contracts: [],
                },

                defaultPlace: {
                    time_from: '',
                    time_to: ''
                },

                defaultCarrierContract: {
                    contact: null,
                    partner: null,
                },

                newDocuments: [],

                dragEnter: false,
            };
        },

        computed: {
            formTitle() {
                return !this.value ? 'Nová zakázka' : 'Úprava zakázky'
            }
        },

        watch: {
            selectedClient(value) {
                if (value) {
                    this.editedItem.client_id = value.id;
                    this.clientContactParams.partner_id = value.id;
                    this.editClientItem = new Partner(value);
                    this.editContactItem = null;
                }
            },

            selectedClientContact(value) {
                if (value) {
                    this.editedItem.client_contact_id = value.id;
                    this.editContactItem = new Contact(value);
                }
            }
        },

        methods: {
            handleEsc(event) {
                if (event.keyCode === 27) {
                    if (confirm('Ukončit bez uložení zakázky?')) {
                        this.close();
                    }
                }
            },

            isRightTab(id) {
                return id === 'documents';
            },

            prevTab() {
                if (this.activeTab > 0) {
                    this.activeTab = this.activeTab - 1;
                }
            },

            nextTab() {
                if (this.activeTab < (this.tabs.length - 1)) {
                    this.activeTab = this.activeTab + 1;
                }
            },

            newClientUpdateItem() {
                this.selectedClient = Object.assign({}, this.newClientItem.attributes);
                this.newClientDialog = false;
            },

            editClientUpdateItem() {
                this.selectedClient = Object.assign({}, this.editClientItem.attributes);
                this.editClientDialog = false;
            },

            newContactUpdateItem() {
                this.selectedClientContact = Object.assign({}, this.newContactItem.attributes);
                this.newContactDialog = false;
            },

            editContactUpdateItem() {
                this.selectedClientContact = Object.assign({}, this.editContactItem.attributes);
                this.editContactDialog = false;
            },

            cleanForm() {
                this.selectedClient = null;
                this.selectedClientContact = null;
                this.editedItem.loadings = [];
                this.editedItem.unloadings = [];
                this.editedItem.carrier_contracts = [];
            },

            fillForm() {
                if (this.client) {
                    this.selectedClient = Object.assign({}, this.client);
                    this.clientContactParams.partner_id = this.client.id;
                } else {
                    this.selectedClient = this.editedItem.client ? Object.assign({}, this.editedItem.client) : null;

                    this.selectedClientContact = this.editedItem.client_contact ? Object.assign({}, this.editedItem.client_contact) : null;
                }
                this.editClientItem = this.selectedClient ? new Partner(this.selectedClient) : null;
                this.selectedLoading = this.editedItem.loading ? Object.assign({}, this.editedItem.loading) : null;
                this.selectedUnloading = this.editedItem.unloading ? Object.assign({}, this.editedItem.unloading) : null;
            },

            addNewLoading() {
                let loading = Object.assign({}, this.defaultPlace);
                this.editedItem.loadings.push(loading);
            },

            removeLoading(idx) {
                let item = this.editedItem.loadings[idx];
                if (item.id) {
                    this.editedItem.deleted_loadings = this.editedItem.deleted_loadings || [];
                    this.editedItem.deleted_loadings.push(item.id);
                }
                this.editedItem.loadings.splice(idx, 1);
            },

            removeUnloading(idx) {
                let item = this.editedItem.unloadings[idx];
                if (item.id) {
                    this.editedItem.deleted_unloadings = this.editedItem.deleted_unloadings || [];
                    this.editedItem.deleted_unloadings.push(item.id);
                }
                this.editedItem.unloadings.splice(idx, 1);
            },

            removeCarrier(idx) {
                let item = this.editedItem.carrier_contracts[idx];
                if (item.id) {
                    this.editedItem.deleted_carrier_contracts = this.editedItem.deleted_carrier_contracts || [];
                    this.editedItem.deleted_carrier_contracts.push(item.id);
                }
                this.editedItem.carrier_contracts.splice(idx, 1);
            },

            addNewUnloading() {
                let unloading = Object.assign({}, this.defaultPlace);
                this.editedItem.unloadings.push(unloading);
            },

            addNewCarrier() {
                this.editedItem.carrier_contracts = this.editedItem.carrier_contracts || [];
                axios.get(Utils.buildUrl(['api', 'setup', 'defaults']), {params: {path: 'contract/carrier'}}).then((result) => {
                    let new_carrier = Object.assign({}, this.defaultCarrierContract);
                    Object.assign(new_carrier, result.data);
                    this.editedItem.carrier_contracts.push(new_carrier); // { price_currency: 'CZK' }
                });
            },

            addNewDocument() {
                let input = document.createElement('input');
                input.type = 'file';
                input.onchange = () => { this.newDocuments.push(input.files[0]); };
                input.click();
            },

            addNewDocuments(e) {
                this.dragEnter = false;
                let dt = e.dataTransfer;
                let files = dt.files;
                [...files].forEach((file) => { this.newDocuments.push(file); });
            },

            deleteNewDocument(idx) {
                this.newDocuments.splice(idx, 1);
            },

            closeContract () {
                if (this.currentItem) {
                    this.currentItem.attributes.state = 1;
                    this.currentItem.save().then((response) => {
                        this.$emit('input', this.currentItem);
                        this.$emit('saved');
                        this.$emit('close');
                    });
                }
            },

            openContract () {
                if (this.currentItem) {
                    this.currentItem.open().then((response) => {
                        this.$emit('input', this.currentItem);
                        this.$emit('saved');
                        this.$emit('close');
                    });
                }
            },

            onClose() {
                if (this.currentItem) {
                    // save current item otherwise it can be nulled before promise
                    let curr = new this.itemClass(this.currentItem.attributes);
                    curr.unlock();
                }
            },

            reloadContract(contract) {
                this.$emit('input', contract);
                this.$emit('saved');
            },

            saveAndUpload() {
                if (this.newDocuments.length > 0) {
                    this.editedItem.newDocuments = this.newDocuments;
                }
                this.save();
            },
        }
    }
</script>

<style lang="scss">
    .contract-form-dialog {
        position: relative;
        height: 95%;
    }

    .contract-form-dialog-content {
        position: absolute;
        top: 0rem;
        bottom: 0rem;
        left: 0rem;
        right: 0rem;
        overflow: auto;
    }

    .dragging {
        background: #128812;
    }
</style>
