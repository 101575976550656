<template>
    <v-card text>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <h1>Zakázka</h1>
                </v-col>
            </v-row>

            <v-card>
                <v-card-title>
                    <h2>Dopravci a objednávky</h2>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="defaults.contract.carrier.due_days" label="Splatnost ve dnech"></v-text-field>
                </v-card-text>
            </v-card>
        </v-card-text>
        <v-card-actions>
            <v-btn primary @click="save">Uložit</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from 'axios'
    import currency from "../mixins/currency";
    import Utils from "../Utils";
    import ErrorHandler from "../ErrorHandler";

    export default {
        name: "SetupDefaults",
        mixins: [currency],

        data: () => {
            return {
                defaults: {
                    contract: {
                        carrier: {
                            price_currency: 'CZK',
                            due_days: ''
                        }
                    }
                },

                saving: false
            };
        },

        created() {
            axios.get(Utils.buildUrl(['api', 'setup', 'defaults'])).then((result) => {
                Object.assign(this.defaults, result.data);
            });
        },

        methods: {
            save() {
                if (this.saving) return;
                this.saving = true;
                axios.post(Utils.buildUrl(['api', 'setup', 'defaults']), {defaults: this.defaults}).then(() => {
                    let note = {
                        group: 'messages',
                        duration: 3000,
                        title: 'Nastavení bylo úspěšně uloženo.'
                    };
                    this.$notify(note);
                }).catch(error => {
                    ErrorHandler.raiseError('Chyba při ukládání.', error);
                }).finally(() => { this.saving = false; });
            }
        }
    }
</script>

<style scoped>

</style>