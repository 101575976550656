import Document from "../models/Document";
import Collection from "../Collection";

export default class Documents extends Collection {
    get url() {
        return '/api/documents';
    }

    get model() {
        return Document;
    }
}
