<template>
    <div>
        <OrderLinkList :orders="orders" @order-deleted="fetchOrders"></OrderLinkList>
        <CarrierCreateOrderButton v-if="carrier && carrier.order_number" :carrier="carrier" @create-order="createOrder"></CarrierCreateOrderButton>
    </div>
</template>

<script>
    import OrderLinkList from "./OrderLinkList";
    import CarrierCreateOrderButton from "./CarrierCreateOrderButton";
    import OrdersCollection from "../collections/Orders";
    import Utils from "../Utils";
    import axios from 'axios';
    import ErrorHandler from "../ErrorHandler";
    import Contract from "../models/Contract";

    export default {
        name: "CarrierOrders",
        props: { carrier: {}, contract: {}, save: { default: true } },

        components: {CarrierCreateOrderButton, OrderLinkList},

        data: function() {
            return {
                orders: []
            };
        },

        created() {
            this.fetchOrders();
        },

        methods: {
            fetchOrders() {
                let orders_collection = new OrdersCollection();
                orders_collection.fetch({ carrier_contract_id: this.carrier.id }).then((data) => {
                    this.orders = data.models;
                });
            },

            createOrder(lang) {
                if (!this.save || this.contract.state === 'closed') {
                    this.generateOrder(lang).then((order) => {
                        this.fetchOrders();
                        this.openOrder(order);
                    });
                } else {
                    let contract = new Contract(this.contract);
                    contract.save().then(() => {
                        this.generateOrder(lang).then((order) => {
                            this.fetchOrders();
                            this.openOrder(order);
                            this.$emit('reload-contract', contract);
                        });
                    });
                }
            },

            generateOrder(lang) {
                return new Promise((resolve, reject) => {
                    axios.get(Utils.buildUrl(['api', 'contracts', this.contract.id, 'generate_order']), {
                        params: {
                            'carrier_contract_id': this.carrier.id,
                            'lang': lang
                        }
                    })
                        .then((response) => {
                            let order = response.data.order;
                            resolve(order);
                        }).catch(error => {
                            ErrorHandler.raiseError('Chyba při vytváření pdf.', error);
                            reject(error);
                        });
                });
            },

            openOrder(order) {
                window.open(Utils.buildUrl(['api', 'orders', order.id, 'download']), '_blank');
            }
        }
    }
</script>

<style scoped>

</style>