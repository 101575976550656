<template>
    <div class="order-link">
        <v-icon @click="deleteDocument" small>mdi-delete</v-icon>
        <a :href="buildUrl(['api', 'documents', item.id, 'download'])" target="_blank">{{ item.file_name }}</a>
        ({{ (item.file_size / 1024 / 1024).toFixed(4) }} MB)
    </div>
</template>

<script>
    import utils from "../mixins/utils";
    import Document from "../models/Document";

    export default {
        name: "DocumentLink",
        mixins: [utils],

        props: ['item'],

        methods: {
            deleteDocument() {
                if (confirm('Smazat dokument?')) {
                    let document = new Document(this.item);
                    document.delete()
                        .then(() => {
                            this.$emit('document-deleted', this.item);
                        });
                }
            }
        }
    }
</script>

<style scoped>

</style>
