import Order from "../models/Order";
import Collection from "../Collection";

export default class Orders extends Collection {
    get url() {
        return '/api/orders';
    }

    get model() {
        return Order;
    }
}