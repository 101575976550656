<template>
    <v-dialog v-model="formDialog">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Exportovat</v-btn>
        </template>
        <v-card>
            <v-card-title>Vyberte od-do a požadované sloupce.</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <DateTimeRangePicker :datetime_from="time_from" :datetime_to="time_to"
                                             @input-from="setTimeFrom" @input-to="setTimeTo"
                                             label_from="Od" label_to="Do"
                        ></DateTimeRangePicker>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-checkbox v-model="columns" label="Zákazník" value="customer"></v-checkbox>
                        <v-checkbox v-model="columns" label="Datum" value="date"></v-checkbox>
                        <v-checkbox v-model="columns" label="Pozice" value="order_number"></v-checkbox>
                        <v-checkbox v-model="columns" label="Datum nakládky" value="loading_date"></v-checkbox>
                        <v-checkbox v-model="columns" label="Místo nakládky" value="loading_place"></v-checkbox>
                        <v-checkbox v-model="columns" label="Datum vykládky" value="unloading_date"></v-checkbox>
                        <v-checkbox v-model="columns" label="Místo vykládky" value="unloading_place"></v-checkbox>
                        <v-checkbox v-model="columns" label="Zboží" value="goods"></v-checkbox>
                        <v-checkbox v-model="columns" label="Dopravce" value="carrier"></v-checkbox>
                        <v-checkbox v-model="columns" label="SPZ" value="spz"></v-checkbox>
                        <v-checkbox v-model="columns" label="Cena od zákazníka" value="client_price"></v-checkbox>
                        <v-checkbox v-model="columns" label="Cena od dopravců" value="carrier_prices"></v-checkbox>
                        <v-checkbox v-model="columns" label="Provize" value="profit"></v-checkbox>
                        <v-checkbox v-model="columns" label="Náklad: Číslo" value="expense_number"></v-checkbox>
                        <v-checkbox v-model="columns" label="Náklad: Splatnost" value="expense_due_on"></v-checkbox>
                        <v-checkbox v-model="columns" label="Vydaná faktura: Variabilní symbol" value="invoice_variable_symbol"></v-checkbox>
                        <v-checkbox v-model="columns" label="Vydaná faktura: Splatnost" value="invoice_due"></v-checkbox>
                        <v-checkbox v-model="columns" label="Poznámky k ceně od zákazníka" value="price_notes"></v-checkbox>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark class="mb-2" @click.prevent="doExport">Exportovat</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import DateTimeRangePicker from "./DateTimeRangePicker";
    import moment from 'moment';
    import Utils from "../Utils";

    export default {
        name: "ContractExportDialog",
        components: {DateTimeRangePicker},
        props: {
            client: {},
        },

        data: () => {
            return {
                formDialog: false,

                time_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                time_to: moment().format('YYYY-MM-DD 23:59:00'),

                columns: ['customer', 'date', 'order_number', 'loading_date', 'loading_place', 'unloading_date', 'unloading_place',
                          'goods', 'carrier', 'spz', 'client_price', 'carrier_prices', 'profit',
                          'expense_number', 'expense_due_on', 'invoice_variable_symbol', 'invoice_due',
                          'price_notes'],
            }
        },

        methods: {
            setTimeFrom(str) {
                this.time_from = str;
            },

            setTimeTo(str) {
                this.time_to = str;
            },

            doExport() {
                let client_id = null;
                if (this.client) {
                    if (this.client.attributes && this.client.attributes.id) {
                        client_id = this.client.attributes.id;
                    } else {
                        client_id = this.client.id;
                    }
                }
                window.location = Utils.buildUrl(['api', 'contracts', 'export'], {
                    client_id: client_id,
                    time_from: this.time_from,
                    time_to: this.time_to,
                    columns: this.columns
                });
            }
        }
    }
</script>

<style scoped>

</style>
