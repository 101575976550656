<template>
    <span class="order-list">
        <DocumentLink v-for="document in documents" :item="document._attributes ? document._attributes : document" @document-deleted="documentDeleted"></DocumentLink>
    </span>
</template>

<script>
    import DocumentLink from "./DocumentLink";

    export default {
        name: "DocumentLinkList",
        components: {DocumentLink},
        props: ['documents'],

        methods: {
            documentDeleted(item) {
                const idx = this.documents.indexOf(item);
                this.documents.splice(idx, 1);
                this.$emit('document-deleted');
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
