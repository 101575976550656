<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>{{ label }}</h1>
        </v-row>

        <SearchField v-model="search" label="Hledat" class="mt-5"></SearchField>

        <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="footerProps"
                sort-by="created_at"
                :sort-desc="true"
        >
            <template v-slot:item="props">
                <tr>
                    <td>{{ formatDate(props.item.attributes.created_at) }}</td>
                    <td>{{ props.item.attributes.order_number }}</td>
                    <td>{{ props.item.attributes.created_by.name }}</td>
                    <td>{{ props.item.attributes.client_name }}</td>
                    <td>{{ props.item.attributes.carrier ? props.item.attributes.carrier.name : '' }}</td>
                    <td>
                        <a :href="buildUrl(['api', 'orders', props.item.attributes.id, 'download'])" target="_blank"><v-icon small class="mr-2" title="Stáhnout">mdi-download</v-icon></a>
                        <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import OrdersCollection from '../collections/Orders';
    import Order from '../models/Order';
    import editable from '../mixins/editable';
    import date from '../mixins/date';
    import utils from '../mixins/utils'
    import SearchField from "./SearchField";

    export default {
        name: "Orders",
        components: {SearchField},
        mixins: [editable, date, utils],

        props: {
            'client': { required: false },
            'carrier': { required: false },
            'label': { required: false, default: 'Objednávky' },
        },

        created() {
            if (this.client) {
                this.fetchOptions.client_id = this.client.id;
            }
            if (this.carrier) {
                this.fetchOptions.carrier_id = this.carrier.id;
            }
        },

        data: () => {
            return {
                controller: 'orders',
                itemClass: Order,
                collectionClass: OrdersCollection,

                headers: [
                    { text: 'Vytvořena', value: 'created_at', sortable: false, width: '10rem' },
                    { text: 'Pozice', value: 'order_number', sortable: false, width: '10rem' },
                    { text: 'Vyřizuje', value: 'created_by', sortable: false },
                    { text: 'Zákazník', value: 'client_name', sortable: false },
                    { text: 'Dopravce', value: 'carrier', sortable: false },
                    { text: 'Akce', sortable: false }
                ],

                defaultItem: {}
            };
        }
    }
</script>

<style scoped>

</style>
