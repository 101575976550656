import axios from 'axios';
import ErrorHandler from './ErrorHandler';
import Utils from "./Utils";

export default class Model {
    constructor(attributes={}) {
        this._attributes = attributes;
    }

    get attributes() {
        return this._attributes;
    }

    set attributes(attributes) {
        this._attributes = attributes;
    }

    get url() {
        throw 'Url not implemented.';
    }

    fetch(params={}) {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            let url = Utils.buildUrl([this.url, id]);
            axios.get(url, { params: params })
                .then(response => {
                    this.attributes = response.data;
                    resolve(this);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při čtení.', error);
                    reject(error);
                });
        });
    }

    save(useFormData = false) {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (id && id !== -1) {
                const method = useFormData ? 'putForm' : 'put';
                let url = Utils.buildUrl([this.url, id]);
                axios[method](url, this.attributes)
                    .then(response => {
                        this.attributes = response.data;
                        resolve(this);
                    })
                    .catch(error => {
                        ErrorHandler.raiseError('Chyba při ukládání.', error);
                        reject(error);
                    });
            } else {
                const method = useFormData ? 'postForm' : 'post';
                let url = Utils.buildUrl([this.url]);
                axios[method](url, this.attributes)
                    .then(response => {
                        this.attributes = response.data;
                        resolve(this);
                    })
                    .catch(error => {
                        ErrorHandler.raiseError('Chyba při vytváření.', error);
                        reject(error);
                    });
            }
        });
    }

    delete() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (id) {
                let url = Utils.buildUrl([this.url, id]);
                axios.delete(url)
                    .then(response => {
                        this.attributes = {};
                        resolve();
                    })
                    .catch(error => {
                        ErrorHandler.raiseError('Položku nelze smazat.', error);
                        reject(error);
                    });
            }
        });
    }
}
