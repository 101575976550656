<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark v-on="on">Vytvořit novou objednávku</v-btn>
        </template>

        <v-list>
            <v-list-item v-for="lang in languages" @click="createOrder(lang.code)">
                <v-icon>mdi-open-in-new</v-icon>
                <v-list-item-title>{{ lang.label }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    import utils from "../mixins/utils";

    export default {
        name: "CarrierCreateOrderButton",
        mixins: [utils],

        props: ['carrier'],

        data: function() {
            return {
                languages: [
                    { code: 'cs', label: 'Česky' },
                    { code: 'en', label: 'Anglicky' }
                ]
            };
        },

        methods: {
            createOrder(lang) {
                this.$emit('create-order', lang);
            }
        }
    }
</script>

<style scoped>

</style>