import axios from 'axios';
import ErrorHandler from '../ErrorHandler';
import Utils from "../Utils";

import Model from "../Model";

export default class Expense extends Model {
    get url() {
        return '/api/expenses';
    }

    static fetchIsdoc(isdoc_file) {
        let url = Utils.buildUrl(['/api/expenses/parse_isdoc']);
        return new Promise((resolve, reject) => {
            axios['postForm'](url, { isdoc_file })
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při vytváření.', error);
                    reject(error);
                });
        });
    }
}
