<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>Místa</h1>
            <v-spacer></v-spacer>
            <PlaceFormDialog v-model="editedItem" :dialog="dialog" @open="openDialog" @close="goToCloseDialog" @saved="updateItem"></PlaceFormDialog>
        </v-row>

        <SearchField v-model="search" label="Hledat"></SearchField>

        <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="footerProps"
                sort-by="name"
        >
            <template v-slot:item="props">
                <tr>
                    <td @click="goToPlace(props.item.attributes.id)">{{ props.item.attributes.name }}</td>
                    <td @click="goToPlace(props.item.attributes.id)">
                        {{ props.item.attributes.street }}<br />
                        {{ props.item.attributes.zip }}  {{ props.item.attributes.city }}<br />
                        {{ props.item.attributes.country }}
                    </td>
                    <td @click="goToPlace(props.item.attributes.id)">{{ props.item.attributes.notes }}</td>
                    <td>
                        <v-icon small class="mr-2" @click="goToPlace(props.item.attributes.id)">mdi-eye</v-icon>
                        <v-icon small class="mr-2" @click="goToEditItem(props.item)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import PlacesCollection from '../collections/Places';
    import Place from '../models/Place';
    import editable from '../mixins/editable';
    import PlaceFormDialog from "./PlaceFormDialog";
    import SearchField from "./SearchField";
    import Utils from "../Utils";

    export default {
        name: "Users",
        components: {SearchField, PlaceFormDialog},
        mixins: [editable],
        
        data: () => {
            return {
                controller: 'places',
                itemClass: Place,
                collectionClass: PlacesCollection,

                headers: [
                    { text: 'Název', value: 'name' },
                    { text: 'Adresa', value: 'address' },
                    { text: 'Poznámky', value: 'notes' },
                    { text: 'Akce', value: 'action', sortable: false }
                ],

                defaultItem: {
                    name: '',
                    street: '',
                    city: '',
                    zip: '',
                    country: '',
                    notes: ''
                }
            };
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nové místo' : 'Úprava místa'
            }
        },

        methods: {
            goToPlace(id) {
                this.$router.push(Utils.buildUrl(['places', id]));
            }
        }
    }
</script>

<style scoped>

</style>