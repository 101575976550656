<template>
    <div class="contract-place-info" v-if="item">
        <div v-if="!short">
            {{ formatDateRange(item.time_from, item.time_to) }}
            {{ item.time_notes }}
            <br />
            <PlaceInfo :item="item.place" :notes-enabled="false"></PlaceInfo>
            <br />
            {{ item.notes }}
        </div>
        <div v-else-if="item.place">
            {{ formatDateOnly(item.time_from) }}, {{ item.place.name }}, {{ item.place.city }}
        </div>
    </div>
</template>

<script>
    import PlaceInfo from "./PlaceInfo";
    import date from '../mixins/date';

    export default {
        name: "ContractPlaceInfo",
        mixins: [date],
        components: {PlaceInfo},
        props: {
            item: {},
            short: { default: false }
        }
    }
</script>

<style scoped>

</style>