<template>
    <v-dialog v-model="formDialog">
        <template v-if="newEnabled" v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ newLabelComputed }}</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container class="grey-background">
                    <v-card class="pa-3">
                        <v-row wrap>
                            <v-col cols="12">
                                <TextField v-model="editedItem.name" label="Název"></TextField>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <TextField v-model="editedItem.street" label="Ulice"></TextField>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <TextField v-model="editedItem.city" label="Město"></TextField>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <TextField v-model="editedItem.zip" label="PSČ"></TextField>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <TextField v-model="editedItem.country" label="Země"></TextField>
                            </v-col>
                            <v-col cols="12">
                                <TextArea v-model="editedItem.notes" label="Poznámky"></TextArea>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                <v-btn color="blue darken-1" text @click="save">Uložit a zavřít</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Place from "../models/Place";
    import editableDialog from "../mixins/editableDialog";
    import TextArea from "./TextArea";
    import TextField from "./TextField";

    export default {
        name: "PlaceFormDialog",
        components: {TextField, TextArea},
        mixins: [editableDialog],

        props: {
            newEnabled: { default: true }
        },

        data: () => {
            return {
                itemClass: Place,

                defaultItem: {
                    name: '',
                    street: '',
                    city: '',
                    zip: '',
                    country: '',
                    notes: ''
                }
            };
        },

        computed: {
            formTitle() {
                return !this.value ? 'Nové místo' : 'Úprava místa'
            },

            newLabelComputed() {
                return !this.newLabel ? 'Nové místo' : this.newLabel;
            }
        }
    }
</script>

<style scoped>

</style>