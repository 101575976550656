<template>
    <div>
        <h1>Zisk</h1>
        <LineChart url="/api/reports/profit" label="Zisk"></LineChart>
    </div>
</template>

<script>
    import LineChart from "./LineChart";

    export default {
        name: "ReportProfit",
        components: {LineChart},
    }
</script>

<style scoped>

</style>
