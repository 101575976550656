import Partner from "../models/Partner";
import Collection from "../Collection";

export default class Partners extends Collection {
    get url() {
        return '/api/partners';
    }

    get model() {
        return Partner;
    }
}