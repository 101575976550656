export default class ErrorHandler {
    static raiseError(message, error) {
        if (error) {
            if (error.response) {
                let data = error.response.data;
                console.log(data);
                if (data.messages) {
                    data.messages.forEach((msg) => {
                        window.vm.$emit('error', msg);
                    })
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message)
            }
        }
        window.vm.$emit('error', message);
    }
}