<template>
    <v-dialog v-model="formDialog">
        <template v-if="newEnabled" v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ newLabelComputed }}</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container class="grey-background">
                    <v-card class="pa-3">
                        <v-row wrap>
                            <v-col cols="12">
                                <TagAutocomplete v-model="editedItem.tags" url="/api/tags/search" label="Štítky"></TagAutocomplete>
                            </v-col>
                            <v-col cols="12">
                                <TextField v-model="editedItem.name" label="Jméno"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.phone" label="Telefon"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.fax" label="Fax"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.email" label="Email"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.street" label="Ulice"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.city" label="Město"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.zip" label="PSČ"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.country" label="Země"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.raal" label="Raal"></TextField>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <TextField v-model="editedItem.timocom" label="Timocom"></TextField>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <TextField v-model="editedItem.ico" label="ICO"></TextField>
                            </v-col>
                            <v-col cols="12" sm="1">
                                <v-icon v-if="!loadingAres" @click="getDataFromAres()">mdi-settings-transfer</v-icon>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <TextField v-model="editedItem.dic" label="DIC"></TextField>
                            </v-col>
                            <v-col cols="12">
                                <TextArea v-model="editedItem.notes" label="Notes"></TextArea>
                            </v-col>
                            <v-col cols="12">
                                <h3>Provozovny</h3>
                                <v-row v-for="address in editedItem.addresses" wrap>
                                    <v-col>
                                        <TextField v-model="address.street" label="Ulice"></TextField>
                                    </v-col>
                                    <v-col>
                                        <TextField v-model="address.city" label="Město"></TextField>
                                    </v-col>
                                    <v-col>
                                        <TextField v-model="address.zip" label="PSČ"></TextField>
                                    </v-col>
                                    <v-col>
                                        <TextField v-model="address.country" label="Země"></TextField>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox v-model="address.invoicing" label="Fakturace?" class="mt-0 pt-2"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn color="primary"
                                               dark
                                               class="mb-2"
                                               @click="editedItem.addresses.push({})"
                                        >Přidat provozovnu</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <h3>Fakturoid</h3>
                                <v-row wrap>
                                    <v-col>
                                        <TextField v-model="editedItem.invoicing_due" label="Splatnost" type="number"></TextField>
                                    </v-col>
                                    <v-col>
                                        <TextArea v-model="editedItem.invoicing_sending_notes" label="Způsob odesílání faktur"></TextArea>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="!editedItem.id" cols="12" v-for="contact in editedItem.new_contacts" >
                                <ContactInfo :item="contact"></ContactInfo>
                            </v-col>
                            <v-col v-if="!editedItem.id" cols="12">
                                <ContactFormDialog :partner="editedItem" v-model="newContactItem"
                                                   :dialog="newContactDialog" @open="newContactDialog = true" @close="newContactDialog = false"
                                                   @saved="newContactUpdateItem" :false-save="true"></ContactFormDialog>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                <v-btn color="blue darken-1" text @click="save">Uložit a zavřít</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Partner from "../models/Partner";
    import editableDialog from "../mixins/editableDialog";
    import TagAutocomplete from "./TagAutocomplete";
    import TextArea from "./TextArea";
    import ContactInfo from "./ContactInfo";
    import ContactFormDialog from "./ContactFormDialog";
    import axios from 'axios';
    import Utils from "../Utils";
    import ErrorHandler from "../ErrorHandler";
    import Ares from "../Ares";
    import TextField from "./TextField";

    export default {
        name: "PartnerFormDialog",
        components: {TextField, ContactFormDialog, ContactInfo, TextArea, TagAutocomplete},
        props: {
            newEnabled: { default: true }
        },

        mixins: [editableDialog],

        data: () => {
            return {
                itemClass: Partner,

                newContactItem: null,
                newContactDialog: false,

                loadingAres: false,

                defaultItem: {
                    name: '',
                    phone: '',
                    fax: '',
                    email: '',
                    street: '',
                    city: '',
                    zip: '',
                    country: '',
                    raal: '',
                    timocom: '',
                    ico: '',
                    dic: '',
                    notes: '',
                    tags: [],
                    new_contacts: [],
                    addresses: [],
                }
            };
        },

        computed: {
            formTitle() {
                return !this.value ? 'Nový partner' : 'Úprava partnera'
            },

            newLabelComputed() {
                return !this.newLabel ? 'Nový partner' : this.newLabel;
            }
        },

        methods: {
            newContactUpdateItem() {
                this.editedItem.new_contacts.push(Object.assign({}, this.newContactItem.attributes));
                this.newContactDialog = false;
            },

            getDataFromAres() {
                if (!this.editedItem.ico) {
                    ErrorHandler.raiseError('Není zadané IČO.');
                } else {
                    this.loadingAres = true;
                    Ares.getBasic(this.editedItem.ico).then((ares) => {
                        this.editedItem.name = ares.name;
                        this.editedItem.street = ares.street;
                        this.editedItem.city = ares.city;
                        this.editedItem.zip = ares.zip;
                        this.editedItem.country = ares.country;
                        this.editedItem.dic = ares.dic;
                    }).catch((error) => {
                        ErrorHandler.raiseError('Nepodařilo se získat informace o subjektu '+ico, error);
                    }).finally(() => { this.loadingAres = false; });
                }
            }
        }
    }
</script>

<style scoped>

</style>
