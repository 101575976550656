<template>
    <v-card text>
        <v-card-text>
            <v-row>
                <v-col v-for="file in files" cols="12">
                    <a :href="buildUrl(['api', 'setup', 'backup', file])">{{ file }}</a>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import axios from 'axios'
    import Utils from "../Utils";
    import utils from "../mixins/utils";

    export default {
        name: "SetupBackups",
        mixins: [utils],

        data: () => {
            return {
                files: []
            };
        },

        created() {
            axios.get(Utils.buildUrl(['api', 'setup', 'backups'])).then((result) => {
                this.files = result.data;
            });
        }
    }
</script>

<style scoped>

</style>