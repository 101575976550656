<template>
    <v-card text>
        <v-card-text>

            <v-row wrap>
                <v-col cols="12">
                    <img :src="buildUrl(['logo.png'])" height="100px" />
                </v-col>
                <v-col cols="12">
                    <form id="logo" :action="buildUrl(['api', 'setup', 'logo'])" method="post" enctype="multipart/form-data">
                        <input id="authenticity_token" type="hidden" name="authenticity_token" />
                        Vyberte obrázek .png k nahrání:
                        <input type="file" name="logo" id="fileToUpload">
                        <input type="submit" value="Poslat obrázek" name="submit" class="v-btn primary dark pa-2">
                    </form>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>
</template>

<script>
    import axios from 'axios'
    import utils from "../mixins/utils";

    export default {
        name: "SetupLogo",
        mixins: [utils],

        data: () => {
            return {};
        },

        mounted() {
            this.$nextTick(function () {
                let token_input = document.querySelector('#authenticity_token');
                token_input.value = axios.defaults.headers.common['X-CSRF-Token'];
            });
        }
    }
</script>

<style scoped>

</style>