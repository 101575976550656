import Expense from "../models/Expense";
import Collection from "../Collection";

export default class Expenses extends Collection {
    get url() {
        return '/api/expenses';
    }

    get model() {
        return Expense;
    }
}
