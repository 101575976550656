<template>
    <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            :label="label"
            clearable
            dense
    ></v-text-field>
</template>

<script>
    export default {
        name: "SearchField",
        props: ['value', 'label'],

        data: () => {
            return {
                searchText: '',
                lastSearch: '',
                doSearchRunning: false
            };
        },

        created() {
            this.searchText = this.value;
        },

        watch: {
            value(val) {
                this.searchText = val;
            },

            searchText(value) {
                if (!this.doSearchRunning) {
                    this.doSearch();
                }
            }
        },

        methods: {
            doSearch() {
                if (this.lastSearch === this.searchText) {
                    this.doSearchRunning = false;
                    this.$emit('input', this.searchText)
                } else {
                    this.doSearchRunning = true;
                    this.lastSearch = this.searchText;
                    setTimeout(() => {
                        this.doSearch();
                    }, 500);
                }
            }
        }
    }
</script>

<style scoped>

</style>