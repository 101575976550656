import Model from "../Model";
import axios from "axios";
import ErrorHandler from "../ErrorHandler";
import Utils from "../Utils";

export default class Contract extends Model {
    get url() {
        return '/api/contracts';
    }

    isClosed() {
        return this.attributes.state === 'closed';
    }

    isLocked() {
        return this.attributes.locked_by !== undefined && this.attributes.locked_by !== null && this.attributes.locked_by !== '';
    }

    isHighlighted() {
        const res = this.attributes.highlighted === true || this.attributes.highlighted === 'true';
        return res;
    }

    isNotBilled() {
        const res = this.attributes.not_billed === true || this.attributes.not_billed === 'true';
        return res;
    }

    canEdit() {
        return !this.isClosed() && !this.isLocked();
    }

    backgroundColor(dark = false) {
        if (!this.isClosed() && !this.isLocked()) {
            // saved
            return dark ? '#229822' : 'lightgreen';
        } else if (this.isClosed() && !this.isLocked()) {
            // closed
            return 'transparent';
        } else if (this.isLocked()) {
            // locked
            return dark ? '#881212' : '#FF7777';
        }

        return false;
    }

    save() {
        return new Promise((resolve, reject) => {
            this.measureDistance().then(result => {
                if (result) {
                    this.attributes.distance = result.distance;
                    this.attributes.start_address = result.start_address;
                    this.attributes.end_address = result.end_address;
                }
                if (this.attributes.newDocuments && this.attributes.newDocuments.length > 0) {
                    let newDocuments = this.attributes.newDocuments;
                    super.save().then(saveResult => {
                        this.uploadNewDocuments(newDocuments).then(uploadResult => {
                            resolve(super.save());
                        });
                    });
                } else {
                    resolve(super.save());
                }
            })
        });
    }

    uploadNewDocuments(newDocuments) {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for (let i=0; i < newDocuments.length; ++i) {
                let document = newDocuments[i];
                formData.append('documents[]', document);
            }

            let url = Utils.buildUrl([this.url, this.attributes.id, 'upload_documents']);
            axios.post(url, formData)
                .then(uploadResult => {
                    resolve(uploadResult);
                });
        });
    }

    measureDistance() {
        return new Promise((resolve, reject) => {
            let result = {};

            if (this.attributes.loadings.length <= 0 || this.attributes.unloadings.length <= 0) {
                console.log('no loadings or unloadings');
                resolve(result);
            } else {
                let last_unloading_idx = this.attributes.unloadings.length - 1;
                let start_place = this.placeToAddress(this.attributes.loadings[0].place);
                let end_place = this.placeToAddress(this.attributes.unloadings[last_unloading_idx].place);
                if (!start_place || !end_place) {
                    console.log('no start or end place');
                    resolve(result);
                } else if (start_place === this.attributes.start_address && end_place === this.attributes.end_address) {
                    console.log('same start and end places');
                    resolve(result);
                } else {
                    let start_coords = null;
                    let end_coords = null;
                    new SMap.Geocoder(start_place, (start_geocoder) => {
                        start_coords = this.getCoordsFromGeocoder(start_geocoder);
                        new SMap.Geocoder(end_place, (end_geocoder) => {
                            end_coords = this.getCoordsFromGeocoder(end_geocoder);
                            if (!start_coords || !end_coords) {
                                console.log('no coords');
                                console.log(start_place);
                                console.log(start_coords);
                                console.log(end_place);
                                console.log(end_coords);
                                resolve(result);
                            } else {
                                let coords = [start_coords, end_coords];
                                new SMap.Route(coords, (route) => {
                                    let route_results = route.getResults();
                                    result.distance = route_results.length;
                                    result.time = route_results.time;
                                    result.start_address = start_place;
                                    result.end_address = end_place;
                                    resolve(result);
                                });
                            }
                        });
                    });
                }
            }
        });
    }

    getCoordsFromGeocoder(geocoder) {
        let results = geocoder.getResults()[0].results;
        if (!results.length) {
            return null;
        }

        let item = results.shift();
        return item.coords;
    }

    placeToAddress(place) {
        if (!place || !place.city) return null;
        // let address = [place.street, place.zip, place.city, place.country].filter(entry => entry !== null && entry !== '');
        let address = [place.zip, place.city].filter(entry => entry !== null && entry !== '');
        return address.join(',');
    }

    delete() {
        return new Promise((resolve, reject) => {
            this.ordersCount()
                .then(count => {
                    let perform = false;
                    if (count > 0) {
                        if (confirm('Zakázka obsahuje ' + count + ' objednávek. Opravdu smazat?')) {
                            perform = true;
                        }
                    } else {
                        perform = true;
                    }

                    if (perform) {
                        super.delete().then(result => resolve(result))
                    }
                })
        });
    }

    ordersCount() {
        return new Promise(((resolve, reject) => {
            let id = this.attributes.id;
            let url = Utils.buildUrl([this.url, id, 'orders_count']);
            axios.get(url)
                .then(response => {
                    resolve(response.data.count);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Nelze zjistit počet objednávek.', error);
                    reject(error);
                })
        }));
    }

    open() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            let url = Utils.buildUrl([this.url, id, 'open']);
            axios.patch(url)
                .then(response => {
                    this.attributes.state = 'open';
                    this.attributes.highlighted = response.data.highlighted;
                    resolve(response);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při otevirání.', error);
                    reject(error);
                });
        });
    }

    close() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            let url = Utils.buildUrl([this.url, id, 'close']);
            axios.patch(url)
                .then(response => {
                    this.attributes.state = 'closed';
                    resolve(response);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při uzavírání.', error);
                    reject(error);
                });
        });
    }

    unlock() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            let url = Utils.buildUrl([this.url, id, 'unlock']);
            axios.patch(url)
                .then(response => {
                    this.attributes.locked_by = null;
                    resolve(response);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při odemykání.', error);
                    reject(error);
                });
        });
    }

    forceUnlock() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            let url = Utils.buildUrl([this.url, id, 'force_unlock']);
            axios.patch(url)
                .then(response => {
                    this.attributes.locked_by = null;
                    resolve(response);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při násilném odemykání.', error);
                    reject(error);
                });
        });
    }

    toggleHighlighted() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            let url = Utils.buildUrl([this.url, id, 'toggle_highlighted']);
            axios.patch(url)
                .then(response => {
                    this.attributes.highlighted = response.data.highlighted;
                    resolve(response);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při zvýrazňování.', error);
                    reject(error);
                });
        });
    }

    toggleNotBilled() {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            if (!id) {
                throw 'Id not provided.';
            }

            if (this.isClosed() || this.isLocked()) {
                resolve(null);
                return;
            }

            let url = Utils.buildUrl([this.url, id, 'toggle_not_billed']);
            axios.patch(url)
                .then(response => {
                    this.attributes.not_billed = response.data.not_billed;
                    resolve(response);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při změně fakturace.', error);
                    reject(error);
                });
        });
    }

    async invoiceGenerators() {
        const url = Utils.buildUrl(['/api/invoices/generators']);
        const response = await axios.get(url);

        return response.data;
    }

    async invoiceBankAccounts() {
        const url = Utils.buildUrl(['/api/invoices/bank_accounts']);
        const response = await axios.get(url);

        return response.data;
    }

    clone() {
        return new Promise((resolve, reject) => {
            this.fetch()
                .then(() => {
                    let the_clone = new Contract(JSON.parse(JSON.stringify(this.attributes)));
                    the_clone.attributes.id = -1;
                    // the_clone.attributes.carrier_contracts = [];
                    the_clone.attributes.carrier_contracts.forEach((cc) => {
                        cc.id = null;
                        cc.number = null;
                        cc.contact = null;
                        cc.partner = null;
                        cc.contact_id = null;
                        cc.created_at = null;
                        cc.old_number = null;
                        cc.partner_id = null;
                        cc.updated_at = null;
                        cc.contact_id = null;
                        cc.week_number = null;
                        cc.year_number = null;
                        cc.month_number = null;
                        cc.order_number = null;
                        cc.order_number_created_at = null;
                        cc.goods = null;
                    });
                    the_clone.attributes.loadings.forEach((loading) => {
                        loading.time_from = this.setDateToNow(loading.time_from);
                        loading.time_to = this.setDateToNow(loading.time_to);
                    });
                    the_clone.attributes.unloadings.forEach((unloading) => {
                        unloading.time_from = this.setDateToNow(unloading.time_from);
                        unloading.time_to = this.setDateToNow(unloading.time_to);
                    });
                    the_clone.attributes.state = 'open';
                    the_clone.attributes.highlighted = false;
                    resolve(the_clone);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při klonování.', error);
                    reject(error);
                });
        });
    }

    setDateToNow(time) {
        let t = new Date(time);
        let now = new Date();

        now.setMilliseconds(0);
        now.setSeconds(0);
        now.setMinutes(t.getMinutes());
        now.setHours(t.getHours());

        return now.toISOString();
    }
}
