export default {
    data: () => {
        return {
            user: window.current_user,
        };
    },

    methods: {
        isPagePermitted(page) {
            if (!this.user || !this.user.permissions || !this.user.permissions[page]) {
                return false;
            }
            return this.user.permissions[page].includes('read');
        },
    }
}
