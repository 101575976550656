<template>
    <div class="mx-3 mb-3">
        <v-row row wrap>
            <h1>{{ label }}</h1>
        </v-row>

        <SearchField v-model="search" label="Hledat" class="mt-5"></SearchField>

        <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="footerProps"
                sort-by="created_at"
                :sort-desc="true"
        >
            <template v-slot:item="props">
                <tr>
                    <td>{{ formatDate(props.item.attributes.created_at) }}</td>
                    <td>{{ props.item.attributes.variable_symbol }}</td>
                    <td>{{ props.item.attributes.fakturoid_id }}</td>
                    <td>{{ props.item.attributes.carrier_name }}</td>
                    <td>
                        <a :href="buildUrl(['api', 'expenses', props.item.attributes.id, 'download'])" target="_blank"><v-icon small class="mr-2" title="Stáhnout">mdi-download</v-icon></a>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import ExpensesCollection from '../collections/Expenses';
    import Expense from '../models/Expense';
    import editable from '../mixins/editable';
    import date from '../mixins/date';
    import utils from '../mixins/utils'
    import SearchField from "./SearchField";

    export default {
        name: "Expenses",
        components: {SearchField},
        mixins: [editable, date, utils],

        props: {
            'client': { required: false },
            'label': { required: false, default: 'Náklady' },
        },

        created() {
            if (this.client) {
                this.fetchOptions.client_id = this.client.id;
            }
        },

        data: () => {
            return {
                controller: 'expenses',
                itemClass: Expense,
                collectionClass: ExpensesCollection,

                headers: [
                    { text: 'Vytvořena', value: 'created_at', sortable: false, width: '10rem' },
                    { text: 'Variabilní symbol', value: 'variable_symbol', sortable: false, width: '10rem' },
                    { text: 'Fakturoid ID', value: 'fakturoid_id', sortable: false },
                    { text: 'Dopravce', value: 'carrier_name', sortable: false },
                    { text: 'Akce', sortable: false }
                ],

                defaultItem: {}
            };
        }
    }
</script>

<style scoped>

</style>
