<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>Partneři</h1>

            <v-spacer></v-spacer>
            <a :class="{ 'filter': true, active: !fetchOptions.filter }" @click.prevent="showAll">Všichni</a>
            <a :class="{ 'filter': true, active: fetchOptions.filter === 'clients' }" @click.prevent="showClients">Zákazníci</a>
            <a :class="{ 'filter': true, active: fetchOptions.filter === 'carriers' }" @click.prevent="showCarriers">Dopravci</a>
            <a :class="{ 'filter': true, active: fetchOptions.filter === 'both' }" @click.prevent="showBoth">Obojí</a>
            <v-spacer></v-spacer>

            <PartnerFormDialog v-model="editedItem" :dialog="dialog" @open="openDialog" @close="goToCloseDialog" @saved="updateItem"></PartnerFormDialog>
        </v-row>

        <SearchField v-model="search" label="Hledat"></SearchField>

        <v-chip v-for="tag in tags"
                small
                @click="showTag(tag)"
                :class="{ active: fetchOptions.tags && fetchOptions.tags.indexOf(tag) >= 0 }"
        >{{ tag }}</v-chip>

        <br class="mb-3"/>

        <div v-if="canDeleteTag()" class="mb-3">
            <v-btn @click.prevent="deleteSelectedTag">Smazat vybraný tag</v-btn>
        </div>

        <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :loading="loading"
                item-key="attributes.id"
                class="elevation-1"
                :footer-props="footerProps"
                sort-by="name"
        >
            <template v-slot:item="props">
                <tr class="slim-row">
                    <td @click="goToPartner(props.item.attributes.id)">
                        <v-chip v-for="tag in props.item.attributes.tags" small outlined>{{ tag }}</v-chip>
                    </td>
                    <td @click="goToPartner(props.item.attributes.id)" class="short-column">{{ props.item.attributes.name }}</td>
                    <td>
                        <PhoneInfo :phone="props.item.attributes.phone"></PhoneInfo>
                    </td>
                    <td>
                        <EmailInfo :email="props.item.attributes.email"></EmailInfo>
                    </td>
                    <td @click="goToPartner(props.item.attributes.id)">
                        {{ props.item.attributes.contacts.map(contact => contact.name).join(', ') }}
                    </td>
                    <td @click="goToPartner(props.item.attributes.id)">
                        {{ props.item.attributes.street }}<br />
                        {{ props.item.attributes.zip }}  {{ props.item.attributes.city }}<br />
                        {{ props.item.attributes.country }}
                    </td>
                    <td @click="goToPartner(props.item.attributes.id)">{{ props.item.attributes.raal }}</td>
                    <td @click="goToPartner(props.item.attributes.id)">{{ props.item.attributes.timocom }}</td>
                    <td @click="goToPartner(props.item.attributes.id)">{{ props.item.attributes.ico }}</td>
                    <td @click="goToPartner(props.item.attributes.id)">
                        <pre style="white-space: break-spaces;">{{ props.item.attributes.notes }}</pre>
                    </td>
                    <td>
                        <router-link :to="buildUrl(['partners', props.item.attributes.id])"><v-icon small class="mr-2">mdi-eye</v-icon></router-link>
                        <v-icon small class="mr-2" @click="goToEditItem(props.item)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import Partner from '../models/Partner';
    import PartnersCollection from '../collections/Partners';
    import PhoneInfo from "./PhoneInfo";
    import Contacts from "./Contacts";
    import PartnerFormDialog from "./PartnerFormDialog";
    import editable from "../mixins/editable";
    import EmailInfo from "./EmailInfo";
    import SearchField from "./SearchField";
    import utils from "../mixins/utils";
    import Utils from "../Utils";
    import axios from "axios";

    export default {
        name: "Partner",
        components: {SearchField, EmailInfo, PartnerFormDialog, PhoneInfo, Contacts},
        mixins: [editable, utils],

        data: () => {
            return {
                controller: 'partners',
                itemClass: Partner,
                collectionClass: PartnersCollection,

                tags: [],

                headers: [
                    { text: 'Štítky', value: 'tags', sortable: false, width: '1rem' },
                    { text: 'Jméno', value: 'name' },
                    { text: 'Telefon', value: 'phone' },
                    { text: 'Email', value: 'email' },
                    { text: 'Kontakty', value: 'contacts', sortable: false },
                    { text: 'Address', value: 'city' },
                    { text: 'Raal', value: 'raal' },
                    { text: 'Timocom', value: 'timocom' },
                    { text: 'ICO', value: 'ico' },
                    { text: 'Poznámky', value: 'notes' },
                    { text: 'Akce', value: 'action', sortable: false }
                ]
            };
        },

        methods: {
            showAll() {
                this.fetchOptions.filter = undefined;
                this.fetchItems();
            },

            showClients() {
                this.fetchOptions.filter = 'clients';
                this.fetchItems();
            },

            showCarriers() {
                this.fetchOptions.filter = 'carriers';
                this.fetchItems();
            },

            showBoth() {
                this.fetchOptions.filter = 'both';
                this.fetchItems();
            },

            showTag(tag) {
                this.fetchOptions.tags = this.fetchOptions.tags || [];
                if (this.fetchOptions.tags.indexOf(tag) >= 0) {
                    this.fetchOptions.tags = [];
                } else {
                    this.fetchOptions.tags = [tag];
                }
                this.fetchItems();
            },

            canDeleteTag() {
                return (this.fetchOptions.filter === undefined &&
                        this.fetchOptions.tags && this.fetchOptions.tags.length > 0 &&
                        this.items.length === 0);
            },

            deleteSelectedTag() {
                const tag = this.fetchOptions.tags[0];
                if (confirm(`Smazat tag ${tag}`)) {
                    axios.delete(Utils.buildUrl(['api', 'tags', tag])).then((response) => {
                        window.location.reload();
                    });
                }
            },

            goToPartner(id) {
                this.$router.push(Utils.buildUrl(['partners', id]));
            }
        },

        created() {
            axios.get(Utils.buildUrl(['api', 'tags'])).then((response) => {
                response.data.models.forEach((item) => {
                    this.tags.push(item.name);
                });
            });
        }
    }
</script>

<style scoped lang="scss">
    a.filter {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        margin: 4px;
    }

    a.active {
        border-radius: 0;
        border-bottom: 1px solid;
    }

    .v-chip.active {
        background-color: cornflowerblue !important;
    }

    .v-chip {
        background-color: transparent !important;
        border: 1px solid #BBBBBB;
        margin: 4px;
    }
</style>