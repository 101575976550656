<template>
    <a :href="'tel:' + phoneNoSpaces" v-if="phone" class="phone">{{ phoneFormatted }}</a>
</template>

<script>
    export default {
        name: "PhoneInfo",

        props: ['phone'],

        computed: {
            phoneNoSpaces() {
                return this.removeSpaces(this.phone);
            },

            phoneFormatted() {
                let no_space = this.removeSpaces(this.phone);

                // +420123456789 | 00420123456789
                let result = no_space.match(/^(\+|00)(\d{3})(\d{3})(\d{3})(\d{3})$/);
                if (result) {
                    return result[1] + result[2] + ' ' + result[3] + ' ' + result[4] + ' ' + result[5];
                }

                // +48123456789 | 0048123456789
                result = no_space.match(/^(\+|00)(\d{2})(\d{3})(\d{3})(\d{3})$/);
                if (result) {
                    return result[1] + result[2] + ' ' + result[3] + ' ' + result[4] + ' ' + result[5];
                }

                // 123456789
                result = no_space.match(/^(\d{3})(\d{3})(\d{3})$/);
                if (result) {
                    return result[1] + ' ' + result[2] + ' ' + result[3];
                }

                // 12345678
                result = no_space.match(/^(\d{3})(\d{2})(\d{3})$/);
                if (result) {
                    return result[1] + ' ' + result[2] + ' ' + result[3];
                }

                return no_space;
            }
        },

        methods: {
            removeSpaces(str) {
                return str.replace(/\s+/g, '');
            }
        }
    }
</script>

<style scoped lang="scss">
    .phone:not(:first-of-type):before {
        content: ', ';
        margin-right: 1rem;
    }
</style>
