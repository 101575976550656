<template>
    <v-dialog v-model="formDialog">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">{{ newLabelComputed }}</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
                <v-container class="grey-background">
                    <v-card class="pa-3">
                        <v-row wrap>
                            <v-col v-if="!falseSave" cols="12">
                                <InfoAutocomplete v-model="local_partner" :url="url" :label="label" v-slot="props">
                                    <PartnerInfo :item="props.item"></PartnerInfo>
                                </InfoAutocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-checkbox v-model="editedItem.invoicing" label="Fakturace?" class="mt-0 pt-2"></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <TextField v-model="editedItem.name" label="Jméno"></TextField>
                            </v-col>
                            <v-col cols="12">
                                <TextField v-model="editedItem.job_title" label="Funkce"></TextField>
                            </v-col>
                            <v-col cols="12">
                                <v-label>E-maily</v-label>
                                <v-text-field v-for="(email, idx) in editedItem.emails"
                                              v-model="editedItem.emails[idx]"
                                              dense outlined
                                              clearable clear-icon="mdi-delete" @click:clear="removeEmail(idx)"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="primary" dark @click.prevent="addNewEmail">Přidat e-mail</v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-label>Telefony</v-label>
                                <v-text-field v-for="(phone, idx) in editedItem.phones"
                                              v-model="editedItem.phones[idx]"
                                              dense outlined
                                              clearable clear-icon="mdi-delete" @click:clear="removePhone(idx)"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="primary" dark @click.prevent="addNewPhone">Přidat telefon</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Zrušit</v-btn>
                <v-btn color="blue darken-1" text @click="saveContact">Uložit a zavřít</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Contact from "../models/Contact";
    import editableDialog from "../mixins/editableDialog";
    import InfoAutocomplete from './InfoAutocomplete';
    import PartnerInfo from "./PartnerInfo";
    import TextField from "./TextField";


    export default {
        name: "ContactFormDialog",

        mixins: [editableDialog],
        components: {TextField, InfoAutocomplete, PartnerInfo},

        props: {
            partner: {},
            falseSave: { default: false },
            invoicing: { default: false },
        },

        data: () => {
            return {
                itemClass: Contact,

                local_partner: null,

                newEmail: '@',
                newPhone: '+420',

                defaultItem: {
                    invoicing: false,
                    name: '',
                    job_title: '',
                    emails: [],
                    phones: [],
                    partner: { }
                }
            };
        },

        computed: {
            formTitle() {
                return !this.value ? 'Nová kontaktní osoba' : 'Úprava kontaktní osoby'
            },

            newLabelComputed() {
                return !this.newLabel ? 'Nová kontaktní osoba' : this.newLabel;
            },

            url() {
                return '/api/partners/search';
            },

            label() {
                return 'Partner';
            }
        },

        methods: {
            saveContact() {
                if (!this.falseSave) {
                    this.save();
                } else {
                    let item = new this.itemClass(this.editedItem);
                    this.$emit('input', item);
                    this.$emit('saved');
                }
            },

            addNewEmail() {
                this.editedItem.emails.push('@');
            },

            removeEmail(idx) {
                this.editedItem.emails.splice(idx, 1);
            },

            addNewPhone() {
                this.editedItem.phones.push('+420');
            },

            removePhone(idx) {
                this.editedItem.phones.splice(idx, 1);
            }
        },

        watch: {
            editedItem(newVal, oldVal) {
                if (newVal.partner_id && (newVal.partner_id !== oldVal.partner_id)) {
                    this.local_partner = Object.assign({}, newVal.partner);
                } else {
                    this.local_partner = null;
                }

                if ((!this.local_partner || !this.local_partner.id) && this.partner) {
                    this.local_partner = Object.assign({}, this.partner);
                }
            },

            local_partner(value) {
                if (value) {
                    this.editedItem.partner_id = value.id;
                }
            },

            partner(value) {
                if (this.partner) {
                    this.local_partner = value;
                }
            }
        },

        created() {
            if (this.partner) {
                this.local_partner = this.partner;
            }

            this.defaultItem.invoicing = this.invoicing;
        }
    }
</script>

<style scoped>

</style>
