<template>
    <v-card text>
        <v-card-text>
            <v-tabs>
                <v-tab key="cs">Česky</v-tab>
                <v-tab key="en">Anglicky</v-tab>

                <v-tab-item v-for="lang in ['cs', 'en']" :key="lang">
                    <v-card text>
                        <v-card-text>
                            <v-row wrap>
                                <v-col cols="12">
                                    <TextArea v-model="editedItem.text1[lang]" label="Text 1"></TextArea>
                                </v-col>
                                <v-col cols="12">
                                    <TextArea v-model="editedItem.text2[lang]" label="Text 2"></TextArea>
                                </v-col>
                                <v-col cols="12">
                                    <TextArea v-model="editedItem.text3[lang]" label="Text 3"></TextArea>
                                </v-col>
                                <v-col cols="12">
                                    <TextArea v-model="editedItem.text4[lang]" label="Text 4"></TextArea>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-tab-item>

            </v-tabs>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="saveSetup">Uložit</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import setup from '../mixins/setup';
    import TextArea from "./TextArea";

    export default {
        name: "SetupPdf",
        components: {TextArea},
        mixins: [setup],

        data: () => {
            return {
                defaultItem: {
                    text1: { cs: '', en: '' },
                    text2: { cs: '', en: '' },
                    text3: { cs: '', en: '' },
                    text4: { cs: '', en: '' }
                },

                url: '/api/setup/pdf'
            };
        }
    }
</script>

<style scoped>

</style>