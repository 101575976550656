<template>
    <span class="order-list">
        <OrderLink v-for="order in orders" :item="order" @order-deleted="orderDeleted"></OrderLink>
    </span>
</template>

<script>
    import OrderLink from "./OrderLink";
    export default {
        name: "OrderLinkList",
        components: {OrderLink},
        props: ['orders'],

        methods: {
            orderDeleted(item) {
                this.$emit('order-deleted');
            }
        }
    }
</script>

<style scoped lang="scss">
    /*.order-list .order-link:not(:first-child) {*/
    /*    margin-left: 0.75rem;*/
    /*}*/
</style>