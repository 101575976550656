<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>{{ label }}</h1>
            <v-btn @click="resetSearch" class="mt-2 ml-3">RESET</v-btn>
            <v-checkbox v-model="searchOpen" label="Jen otevřené" class="ml-3 mt-2"></v-checkbox>
            <v-checkbox v-model="searchHighlighted" label="Sledované" class="ml-3 mt-2"></v-checkbox>
            <v-checkbox v-if="isPagePermitted('invoices')" v-model="searchNotBilled" label="Nevyfakturované" class="ml-3 mt-2"></v-checkbox>
            <v-spacer></v-spacer>
            <ContractExportDialog :client="client"></ContractExportDialog>
            &nbsp;
            <ContractFormDialog v-model="editedItem" :dialog="dialog" :client="client" :carrier="carrier" :new-enabled="newEnabled"
                                @open="openDialog" @close="goToCloseDialog" @saved="updateItem"></ContractFormDialog>
        </v-row>

        <v-row row wrap>
            <v-col cols="12" :md="noCarrier ? 6 : false">
                <v-container>
                    <v-row no-gutters>
                        <v-col cols="6" class="pr-1">
                            <SearchField v-model="search" label="Hledat"></SearchField>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <SearchField v-model="searchIco" label="ICO"></SearchField>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pr-1">
                            <SearchField v-model="searchLoading" label="Místo nakládky"></SearchField>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <SearchField v-model="searchUnloading" label="Místo vykládky"></SearchField>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pr-1">
                            <SearchField v-model="searchRaal" label="Raal"></SearchField>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <SearchField v-model="searchTimocom" label="Timocom"></SearchField>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
            <v-col v-if="noCarrier" cols="12" md="6">
                <v-card height="180px" class="contracts-no-carrier pa-2">
                    <table>
                        <tr v-for="contract in contractsNoCarrier" class="d-flex">
                            <td>
                                <router-link :to="buildUrl(['contracts', contract.attributes.id])"><v-icon small class="mr-2">mdi-eye</v-icon></router-link>
                            </td>
                            <td><span v-if="contract.attributes.loadings.length > 0">{{ formatDayMonth(contract.attributes.loadings[0].time_from) }}</span></td>
                            <td>
                                <span v-if="contract.attributes.loadings.length > 0">{{ contract.attributes.loadings.map(loading => loading.place ? loading.place.city : null).filter(city => city !== null).join(', ') }}</span>
                                &ndash;
                                <span v-if="contract.attributes.unloadings.length > 0">{{ contract.attributes.unloadings.map(unloading => unloading.place ? unloading.place.city : null).filter(city => city !== null).join(', ') }}</span>
                            </td>
                            <td>
                                {{ contract.attributes.price_formatted }} {{ contract.attributes.price_currency }}
                                /
                                {{ getCarrierContractsPrices(contract.attributes.carrier_contracts) }}
                            </td>
                        </tr>
                    </table>
                </v-card>
            </v-col>
        </v-row>

        <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :loading="loading"
                item-key="attributes.id"
                class="elevation-1 divider"
                :footer-props="footerProps"
                sort-by="created_at"
                :sort-desc="true"
        >
            <template v-slot:item="props">
                <tr :class="{ saved: !props.item.isClosed() && !props.item.isLocked(), closed: props.item.isClosed() && !props.item.isLocked(), locked: props.item.isLocked(), 'slim-row': true }"
                    :style="{ 'background-color': props.item.backgroundColor($vuetify.theme.dark)}">
                    <td @click="expandContract(props)" class="created_at">
                        {{ carrierContractsDates(props.item.attributes.created_at, props.item.attributes.carrier_contracts) }}
                    </td>
                    <td @click="expandContract(props)">
                        <div v-for="carrier in props.item.attributes.carrier_contracts" class="text-no-wrap">{{ carrier.order_number }}</div>
                    </td>
                    <td @click="expandContract(props)" class="short-column">
                        {{ props.item.attributes.client.name }}
                    </td>
                    <td @click="expandContract(props)" class="short-column">
                        <div v-for="carrier in props.item.attributes.carrier_contracts">
                            {{ carrier.partner ? carrier.partner.name : '' }}
                        </div>
                    </td>
                    <td @click="expandContract(props)">
                        <ContractPlaceInfo v-for="loading in props.item.attributes.loadings" :item="loading" :short="true"></ContractPlaceInfo>
                    </td>
                    <td @click="expandContract(props)">
                        <ContractPlaceInfo v-for="unloading in props.item.attributes.unloadings" :item="unloading" :short="true"></ContractPlaceInfo>
                    </td>
                    <td @click="expandContract(props)" style="">
                        <div v-for="(line, index) in firstTwoLines(props.item.attributes.goods)" class="goods">
                            {{ line }}
                        </div>
                    </td>
                    <td @click="expandContract(props)">
                        {{ props.item.attributes.price_formatted }}&nbsp;{{ props.item.attributes.price_currency }}
                        /
                        {{ getCarrierContractsPrices(props.item.attributes.carrier_contracts) }}
                    </td>
                    <td>
                        <router-link :to="buildUrl(['contracts', props.item.attributes.id])"><v-icon small class="mr-2">mdi-eye</v-icon></router-link>
                        <span v-if="!props.item.isLocked()">
                            <v-icon v-if="props.item.canEdit()" small class="mr-2" @click="goToEditItem(props.item)">mdi-pencil</v-icon>
                            <v-icon v-if="!props.item.isClosed()" small class="mr-2" @click="props.item.close()" alt="Uzavřít" title="Uzavřít">mdi-lock-open-variant</v-icon>
                            <v-icon v-else @click="props.item.open()" small class="mr-2" alt="Otevřít" title="Otevřít">mdi-lock</v-icon>
                        </span>
                        <v-icon small class="mr-2" @click="cloneContract(props.item)">mdi-content-copy</v-icon>
                        <v-icon v-if="props.item.canEdit()" small @click="deleteItem(props.item)">mdi-delete</v-icon>
                        <span v-if="!props.item.isLocked() && !props.item.isClosed()">
                            <span class="yellow-rectangle" :class="{ 'bg-yellow': props.item.isHighlighted() }" @click="props.item.toggleHighlighted()"></span>
                        </span>
                        <span v-if="isPagePermitted('invoices')">
                            <span class="blue-rectangle" :class="{ 'bg-blue': props.item.isNotBilled() }" @click="props.item.toggleNotBilled()"></span>
                        </span>
                    </td>
                </tr>
            </template>

            <template v-slot:expanded-item="props">
                <td :colspan="props.headers.length">
                    <ContractView :item="props.item"
                                  :loading-enabled="true"
                                  :unloading-enabled="true"
                                  :number-enabled="false"
                                  :created-at-enabled="false"
                                  :title-enabled="false"
                                  :price-czk-enabled="false"
                                  :fetch-contract-enabled="false"
                    ></ContractView>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import ContractsCollection from '../collections/Contracts';
    import Contract from '../models/Contract';
    import editable from '../mixins/editable';
    import date from '../mixins/date';
    import InfoAutocomplete from './InfoAutocomplete';
    import PartnerInfo from './PartnerInfo';
    import PlaceInfo from './PlaceInfo';
    import ContactInfo from './ContactInfo';
    import ContractView from './ContractView';
    import ContractFormDialog from "./ContractFormDialog";
    import ContractPlaceInfo from "./ContractPlaceInfo";
    import SearchField from "./SearchField";
    import CarrierInfo from './CarrierInfo';
    import utils from "../mixins/utils";
    import ContractExportDialog from "./ContractExportDialog";
    import permissions from "../mixins/permissions";

    export default {
        name: "Contracts",

        mixins: [editable, date, utils, permissions],

        components: {
            ContractExportDialog,
            SearchField,
            ContractPlaceInfo,
            ContractFormDialog,
            InfoAutocomplete, PartnerInfo, PlaceInfo, ContactInfo, ContractView, CarrierInfo
        },

        props: {
            client: {},
            carrier: {},
            loadingItem: {},
            unloadingItem: {},
            label: { default: 'Zakázky' },
            newEnabled: { default: true },
            noCarrier: { default: true }
        },

        created() {
            if (this.client) {
                this.fetchOptions.client_id = this.client.id;
                this.selectedClient = this.client;
            }
            if (this.carrier) {
                this.fetchOptions.carrier_id = this.carrier.id;
            }
            if (this.loadingItem) {
                this.fetchOptions.loading_id = this.loadingItem.id;
            }
            if (this.unloadingItem) {
                this.fetchOptions.unloading_id = this.unloadingItem.id;
            }
        },

        data: () => {
            return {
                controller: 'contracts',
                itemClass: Contract,
                collectionClass: ContractsCollection,

                headers: [
                    { text: 'Vytvořeno', value: 'created_at', width: '8rem', divider: true, class: 'sticky-header' },
                    { text: 'Pozice', value: 'carrier_contracts.order_number', width: '1rem', divider: true, class: 'sticky-header' },
                    { text: 'Zákazník', value: 'client', sortable: false, width: '10rem', divider: true, class: 'sticky-header' },
                    { text: 'Dopravce', value: 'carrier', sortable: false, width: '10rem', divider: true, class: 'sticky-header' },
                    { text: 'Nakládka', value: 'loading', sortable: false, divider: true, class: 'sticky-header' },
                    { text: 'Vykládka', value: 'unloading', sortable: false, divider: true, class: 'sticky-header' },
                    { text: 'Zboží', value: 'goods', sortable: false, divider: true, class: 'sticky-header' },
                    { text: 'Cena', value: 'price', sortable: false, width: '10rem', divider: true, class: 'sticky-header' },
                    { text: 'Akce', value: 'action', sortable: false, divider: true, class: 'sticky-header' }
                ],

                searchKey: 'contracts:search',
                searchLoading: '',
                searchUnloading: '',
                searchRaal: '',
                searchTimocom: '',
                searchIco: '',
                searchOpen: false,
                searchHighlighted: false,
                searchNotBilled: false,

                contractsNoCarrier: []
            };
        },

        computed: {
            loadSearch() {
                return this.loadFromStorage(this.searchKey);
            },

            loadSearchLoading() {
                return this.loadFromStorage('contracts:search-loading');
            },

            loadSearchUnloading() {
                return this.loadFromStorage('contracts:search-unloading');
            },

            loadSearchRaal() {
                return this.loadFromStorage('contracts:search-raal');
            },

            loadSearchTimocom() {
                return this.loadFromStorage('contracts:search-timocom');
            },

            loadSearchIco() {
                return this.loadFromStorage('contracts:search-ico');
            },

            loadSearchOpen() {
                return this.loadFromStorage('contracts:search-open');
            },

            loadSearchHighlighted() {
                return this.loadFromStorage('contracts:search-highlighted');
            },

            loadSearchNotBilled() {
                return this.loadFromStorage('contracts:search-not-billed');
            }
        },

        beforeMount() {
            this.loading = true;
            this.search = this.loadSearch;
            this.searchLoading = this.loadSearchLoading;
            this.searchUnloading = this.loadSearchUnloading;
            this.searchRaal = this.loadSearchRaal;
            this.searchTimocom = this.loadSearchTimocom;
            this.searchIco = this.loadSearchIco;
            this.searchOpen = this.loadSearchOpen;
            this.searchHighlighted = this.loadSearchHighlighted;
            this.searchNotBilled = this.loadSearchNotBilled;

            /* this.pagination.sortBy = ['created_at'];
            this.pagination.sortDesc = [true]; */
            this.fetchContractsNoCarrier();
            this.loading = false;
        },

        mounted() {
            this.$root.$on('contract:updated', (data) => {
                let item = this.items.find((itm) => { return itm.attributes.id === data.body.id; });
                if (item) {
                    item.fetch();
                }
            });
        },

        beforeRouteLeave (to, from, next) {
            this.$root.$off('contract:updated');
            next();
        },

        watch: {
            searchLoading(value) {
                this.saveToStorage('contracts:search-loading', value);
                this.fetchOptions.search_loading = value;
                this.fetchItems();
            },

            searchUnloading(value) {
                this.saveToStorage('contracts:search-unloading', value);
                this.fetchOptions.search_unloading = value;
                this.fetchItems();
            },

            searchRaal(value) {
                this.saveToStorage('contracts:search-raal', value);
                this.fetchOptions.search_raal = value;
                this.fetchItems();
            },

            searchTimocom(value) {
                this.saveToStorage('contracts:search-timocom', value);
                this.fetchOptions.search_timocom = value;
                this.fetchItems();
            },

            searchIco(value) {
                this.saveToStorage('contracts:search-ico', value);
                this.fetchOptions.search_ico = value;
                this.fetchItems();
            },

            searchOpen(value) {
                this.saveToStorage('contracts:search-open', value);
                this.fetchOptions.search_open = value;
                this.fetchItems();
            },

            searchHighlighted(value) {
                this.saveToStorage('contracts:search-highlighted', value);
                this.fetchOptions.search_highlighted = value;
                this.fetchItems();
            },

            searchNotBilled(value) {
                this.saveToStorage('contracts:search-not-billed', value);
                this.fetchOptions.search_not_billed = value;
                this.fetchItems();
            }
        },

        methods: {
            getCarrierContractsPrices(carrier_contracts) {
                return carrier_contracts.map((cc) => `${cc.price_formatted} ${cc.price_currency}`).join(', ')
            },

            firstTwoLines(str) {
                if (! str) return [];

                let a = str.split("\n");
                if (a.length > 2) {
                    return [a[0], a[1]];
                }

                return a;
            },

            resetSearch() {
                this.loading = true;
                this.search = '';
                this.searchLoading = '';
                this.searchUnloading = '';
                this.searchRaal = '';
                this.searchTimocom = '';
                this.searchIco = '';
                this.searchOpen = false;
                this.searchHighlighted = false;
                this.searchNotBilled = false;
                this.loading = false;
                window.location.reload();
            },

            carrierContractsDates(created_at, ccs) {
                let dates = [];
                if (ccs.length > 0) {
                    ccs.forEach((cc) => {
                        if (cc.order_number_created_at) {
                            dates.push(this.formatDate(cc.order_number_created_at));
                        }
                    });
                }
                if (dates.length <= 0) {
                    return this.formatDate(created_at);
                }
                return dates.join(', ');
            },

            expandContract(props) {
                if (props.isExpanded) {
                    props.expand(false);
                } else {
                    props.item.fetch().then(() => {
                        props.expand(true);
                    });
                }
            },

            afterDelete() {
                this.fetchContractsNoCarrier();
            },

            cloneContract(item) {
                item.clone().then((the_clone) => {
                    this.editItem(the_clone);
                });
            },

            fetchContractsNoCarrier() {
                if (this.noCarrier === false) return;

                let collection = new this.collectionClass();
                let params = { no_carrier: true };
                if (this.client && this.client.id) {
                    params.client_id = this.client.id;
                }
                collection.fetch(params)
                    .then((data) => {
                        this.contractsNoCarrier = data.models;
                    })
                    .finally(() => {
                    });
            },

            afterUpdate() {
                this.fetchContractsNoCarrier();
            },

            performUnshift(itm) {
                if (itm.attributes.carrier_contracts.length <= 0) {
                    return false;
                }
                for (let i = 0; i < itm.attributes.carrier_contracts.length; ++i) {
                    if (! itm.attributes.carrier_contracts[i].order_number) {
                        return false;
                    }
                }
                return true;
            }
        }
    }
</script>

<style lang="scss">
    .closed {
        // background-color: lightgreen;
    }

    .locked {
        // background-color: #881212;
    }

    .saved {
        // background-color: #128812;
    }

    /*tr:hover {*/
    /*    background-color: grey !important;*/
    /*    color: white !important;*/

    /*    .v-icon {*/
    /*        color: white !important;*/
    /*    }*/
    /*}*/
</style>

<style lang="scss">
    .contracts-no-carrier {
        overflow: auto;
        td {
            padding-right: 1rem;
        }
    }

    .created_at {
      padding-left: 0px !important;
      padding-right: 0px !important;
      text-align: center !important;
    }

    .goods {
      //white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 10rem !important;
    }

    .yellow-rectangle {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin-left: 5px;
        border: yellow 1px solid;
    }

    .bg-yellow {
        background-color: yellow;
    }

    .blue-rectangle {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin-left: 5px;
        border: blue 1px solid;
    }

    .bg-blue {
        background-color: blue;
    }
</style>
