<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>{{ label }}</h1>
        </v-row>
        <v-row row wrap>
            <DocumentLinkList :documents="items"></DocumentLinkList>
        </v-row>
    </div>
</template>

<script>
    import DocumentsCollection from '../collections/Documents';
    import Document from '../models/Document';
    import editable from '../mixins/editable';
    import date from '../mixins/date';
    import utils from '../mixins/utils'
    import DocumentLinkList from "./DocumentLinkList.vue";

    export default {
        name: "Documents",
        components: {DocumentLinkList},
        mixins: [editable, date, utils],

        props: {
            'client': { required: false },
            'carrier': { required: false },
            'label': { required: false, default: 'Dokumenty' },
        },

        created() {
            if (this.client) {
                this.fetchOptions.client_id = this.client.id;
            }
            if (this.carrier) {
                this.fetchOptions.carrier_id = this.carrier.id;
            }
        },

        data: () => {
            return {
                controller: 'documents',
                itemClass: Document,
                collectionClass: DocumentsCollection,

                defaultItem: {}
            };
        }
    }
</script>

<style scoped>

</style>
