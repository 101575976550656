<template>
    <div>
        <h1>Výdaje</h1>
        <LineChart url="/api/reports/expenses" label="Výdaje"></LineChart>
    </div>
</template>

<script>
    import LineChart from "./LineChart";

    export default {
        name: "ReportExpenses",
        components: {LineChart},
    }
</script>

<style scoped>

</style>