<template>
    <div>
        <v-select
                v-model="partner"
                :items="entries"
                :loading="isLoading"
                :label="label"
                item-text="name"
                item-value="id"
                placeholder="Vyberte..."
                return-object
                outlined
                hide-details
                dense
        ></v-select>
        <div class="mb-5">
            <slot :item="partner"></slot>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import Utils from "../Utils";

    export default {
        name: "InfoSelect",

        props: ['value', 'url', 'label', 'params', 'useFirst'],

        data: () => {
            return {
                partner: null,
                isLoading: false,
                entries: []
            };
        },

        beforeMount() {
            if (this.value || this.params.partner_id) {
                this.partner = this.value;
                this.searchPartner('');
            }
        },

        watch: {
            partner(value) {
                this.$emit('input', value);
            },

            value(val) {
                this.partner = val;
                if (val) {
                    this.searchPartner('');
                }
            },

            params: {
                handler(val, oldVal) {
                    if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
                        this.partner = null;
                        this.searchPartner('');
                    }
                },
                deep: true
            }
        },

        methods: {
            searchPartner(value) {
                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                let params = Object.assign({}, this.params);
                params.token = value;
                axios.get(Utils.buildUrl([this.url]), { params: params })
                    .then(res => {
                        this.entries = res.data;
                        if (!this.partner && this.useFirst && this.entries.length > 0) {
                            this.partner = this.entries[0];
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (this.isLoading = false))
            }
        }
    }
</script>

<style scoped>

</style>