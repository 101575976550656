import { render, staticRenderFns } from "./Places.vue?vue&type=template&id=05f3710c&scoped=true&"
import script from "./Places.vue?vue&type=script&lang=js&"
export * from "./Places.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f3710c",
  null
  
)

export default component.exports