<template>
    <span class="contact-info" v-if="item">
        {{ item.name }}, {{ item.job_title }},
        <PhoneInfo v-for="phone in item.phones" :phone="phone"></PhoneInfo><EmailInfo v-for="email in item.emails" :email="email"></EmailInfo>,
    </span>
</template>

<script>
    import PhoneInfo from "./PhoneInfo";
    import EmailInfo from "./EmailInfo";

    export default {
        name: "ContactInfo",
        components: {PhoneInfo, EmailInfo},
        props: ['item'],
    }
</script>

<style scoped>

</style>
