<template>
    <v-card v-if="place" text>
        <v-card-title>
            <h1>{{ place.attributes.name }}</h1>
            <v-spacer></v-spacer>
            <v-icon class="mr-2" @click="dialog = true">mdi-pencil</v-icon>
            <v-icon @click="deletePlace()">mdi-delete</v-icon>
            <PlaceFormDialog v-model="place" :dialog="dialog" :new-enabled="false"
                               @open="dialog = true" @close="dialog = false" @saved="dialog = false"></PlaceFormDialog>
        </v-card-title>
        <v-card-text>
            <v-row row wrap>
                <v-col cols="12 pt-5">
                    <table class="place-table">
                        <tr><th>Ulice: </th><td>{{ place.attributes.street }}</td></tr>
                        <tr><th>Město: </th><td>{{ place.attributes.city }}</td></tr>
                        <tr><th>PSČ: </th><td>{{ place.attributes.zip }}</td></tr>
                        <tr><th>Země: </th><td>{{ place.attributes.country }}</td></tr>
                        <tr><th>Poznámky: </th><td><pre style="white-space: break-spaces;">{{ place.attributes.notes }}</pre></td></tr>
                    </table>
                </v-col>
                <v-col cols="12" class="mt-5">
                    <Contracts :loading-item="place.attributes" label="Zakázky jako nakládka" :new-enabled="false" :no-carrier="false" :save-search="false"></Contracts>
                </v-col>
                <v-col cols="12" class="mt-5">
                    <Contracts :unloading-item="place.attributes" label="Zakázky jako vykládka" :new-enabled="false" :no-carrier="false" :save-search="false"></Contracts>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import Place from "../models/Place";
    import Contracts from "./Contracts";
    import PlaceFormDialog from "./PlaceFormDialog";

    export default {
        name: "PlaceView",

        props: {
            item: {},
        },

        components: {PlaceFormDialog, Contracts, Place},

        data: () => {
            return {
                dialog: false,
                place: null
            };
        },

        beforeMount() {
            this.place = this.item;
        },

        mounted() {
            this.$nextTick(function () {
                if (!this.place) {
                    let place = new Place({id: this.$route.params.id});
                    place.fetch().then(() => {
                        this.place = place;
                    });
                }
            });
        },

        methods: {
            deletePlace() {
                if (confirm('Opravdu smazat místo?')) {
                    this.place.delete()
                        .then(() => {
                            this.$router.push({ name: 'places' });
                        });
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .place-table {
        th {
            text-align: left;
        }
    }
</style>