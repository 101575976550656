import Place from "../models/Place";
import Collection from "../Collection";

export default class Places extends Collection {
    get url() {
        return '/api/places';
    }

    get model() {
        return Place;
    }
}