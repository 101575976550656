<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>Uživatelé</h1>
            <v-spacer></v-spacer>
            <UserFormDialog v-model="editedItem" :dialog="dialog" @open="openDialog" @close="goToCloseDialog" @saved="updateUser"></UserFormDialog>
        </v-row>

        <SearchField v-model="search" label="Hledat"></SearchField>


        <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="pagination"
                :server-items-length="totalItems"
                :loading="loading"
                class="elevation-1"
                :footer-props="footerProps"
                sort-by="name"
        >
            <template v-slot:item="props">
                <tr>
                    <td>{{ props.item.attributes.name }}</td>
                    <td>{{ props.item.attributes.email }}</td>
                    <td>{{ props.item.attributes.phone }}</td>
                    <td>
                        <v-icon small class="mr-2" @click="goToEditItem(props.item)">mdi-pencil</v-icon>
                        <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import UsersCollection from '../collections/Users';
    import User from '../models/User';
    import editable from '../mixins/editable';
    import UserFormDialog from "./UserFormDialog";
    import SearchField from "./SearchField";

    export default {
        name: "Users",
        components: {SearchField, UserFormDialog},
        mixins: [editable],
        
        data: () => {
            return {
                controller: 'users',
                itemClass: User,
                collectionClass: UsersCollection,

                headers: [
                    { text: 'Jméno', value: 'name' },
                    { text: 'E-mail', value: 'email' },
                    { text: 'Telefon', value: 'phone' },
                    { text: 'Akce', value: 'action', sortable: false }
                ],

                defaultItem: {
                    name: '',
                    email: '@',
                    phone: '+420',
                    password: ''
                }
            };
        },

        methods: {
            updateUser(item) {
                if (item.attributes._reload_page === true) {
                    window.reload_possible = true;
                    this.$root.$emit('reload-page');
                } else {
                    this.updateItem();
                }
            }
        }
    }
</script>

<style scoped>

</style>