<template>
    <router-view />
</template>

<script>
    import Utils from "../Utils";
    import Tenant from "../Tenant";

    export default {
        name: "TenantHome",

        beforeMount() {
            let re = new RegExp('^/' + Tenant.current + '/?$');
            if (this.$route.path.match(re)) {
                this.$router.push(Utils.buildUrl(['contracts']));
            }
        }
    }
</script>

<style scoped>

</style>