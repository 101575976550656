<template>
    <div>
            <v-navigation-drawer permanent app :clipped="true">
                <v-list>
                    <v-list-item v-for="link in links">
                        <v-list-item-title>
                            <v-btn :to="link.to" text block>{{ link.title }}</v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <router-view />
    </div>
</template>

<script>
    import Utils from "../Utils";

    export default {
        name: "Setup",
        data: () => {
            return {
                links: [
                    { to: Utils.buildUrl(['setup', 'company']), title: 'Firemní údaje' },
                    { to: Utils.buildUrl(['setup', 'pdf']), title: 'Nastavení PDF' },
                    { to: Utils.buildUrl(['setup', 'logo']), title: 'Logo' },
                    { to: Utils.buildUrl(['setup', 'defaults']), title: 'Přednastavené hodnoty' },
                    { to: Utils.buildUrl(['setup', 'backups']), title: 'Zálohy' }
                ]
            };
        }
    }
</script>

<style scoped>

</style>