<template>
    <div>
        <v-combobox
                v-model="tags"
                :items="entries"
                :search-input.sync="searchTag"
                :label="label"
                item-text="name"
                item-value="name"
                placeholder="Začněte psát..."
                chips
                multiple
                hide-selected
                outlined
                dense
        ></v-combobox>
    </div>
</template>

<script>
    import axios from 'axios';
    import Utils from "../Utils";

    export default {
        name: "TagAutocomplete",

        props: ['value', 'url', 'label', 'params'],

        data: () => {
            return {
                tags: [],
                isLoading: false,
                entries: [],
                searchTag: null,
            };
        },

        created() {
            if (this.value) {
                this.entries = this.value;
                this.tags = this.value;
            }
        },

        watch: {
            tags(value) {
                this.$emit('input', value);
            },

            searchTag(value) {
                // Items have already been requested
                if (this.isLoading) return;

                this.isLoading = true;

                // Lazily load input items
                let params = Object.assign({}, this.params);
                params.token = value;
                axios.get(Utils.buildUrl([this.url]), { params: params })
                    .then(res => {
                        this.entries = res.data;
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => (this.isLoading = false))
            },

            value(val) {
                if (val) {
                    this.entries = val;
                } else {
                    this.entries = [];
                }
                this.tags = val;
            }
        }
    }
</script>

<style scoped>

</style>