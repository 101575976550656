import { render, staticRenderFns } from "./SetupPdf.vue?vue&type=template&id=084f7ffc&scoped=true&"
import script from "./SetupPdf.vue?vue&type=script&lang=js&"
export * from "./SetupPdf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084f7ffc",
  null
  
)

export default component.exports