export default {
    data: () => {
        return {
            supplyCodes: [
                { code: '', title: 'Pro souhrnné hlášení DPH' },
                { code: '0', title: '0 - Dodání zboží plátci DPH do EU' },
                { code: '1', title: '1 - Přemístění obchodního majetku plátcem do EU' },
                { code: '2', title: '2 - Dodání zboží uvnitř EU - třistranný obchod' },
                { code: '3', title: '3 - Poskytnutí služby s místem plnění v EU - přenesená DPH' },
                // { code: '', title: 'pro hlášení o fakturách v přenesené daňové povinnosti' },
                // { code: '1', title: '1 – Dodání zlata (plnění podle § 92b)' },
                // { code: '1a', title: '1a – Zlato – zprostředkování dodání investičního zlata' },
                // { code: '3', title: '3 – Dodání nemovité věci, pokud se při tomto dodání uplatní daň (plnění podle § 92d)' },
                // { code: '3a', title: '3a – Dodání nemovité věci v nuceném prodeji' },
                // { code: '4', title: '4 – Poskytnutí stavebních nebo montážních prací (plnění podle § 92e)' },
                // { code: '4a', title: '4a – Stavební a montážní práce – poskytnutí pracovníků' },
                // { code: '5', title: '5 – Zboží uvedené v příloze č. 5 zákona o DPH (plnění podle § 92c)' },
                // { code: '6', title: '6 – Dodání zboží poskytnutého původně jako záruka' },
                // { code: '7', title: '7 – Dodání zboží po postoupení výhrady vlastnictví' },
                // { code: '11', title: '11 – Převod povolenek na emise skleníkových plynů' },
                // { code: '12', title: '12 – Obiloviny a technické plodiny' },
                // { code: '13', title: '13 – Kovy, včetně drahých kovů' },
                // { code: '14', title: '14 – Mobilní telefony' },
                // { code: '15', title: '15 – Integrované obvody a desky plošných spojů' },
                // { code: '16', title: '16 – Přenosná zařízení pro automatizované zpracování dat (např. tablety či laptopy)' },
                // { code: '17', title: '17 – Videoherní konzole' },
                // { code: '18', title: '18 – Dodání certifikátů elektřiny' },
                // { code: '19', title: '19 – Dodání elektřiny soustavami nebo sítěmi obchodníkovi' },
                // { code: '20', title: '20 – Dodání plynu soustavami nebo sítěmi obchodníkovi' },
                // { code: '21', title: '21 – Poskytnutí vymezených služeb elektronických komunikací' },
            ],

            invoiceThemes: [
                { name: 'solaris', title: 'Solaris' },
                { name: 'phoenix', title: 'Fénix' },
                { name: 'orion', title: 'Orion' },
                { name: 'lyra', title: 'Lyra' },
                { name: 'vega', title: 'Vega' },
            ],
        };
    },
}