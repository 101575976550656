import axios from 'axios';
import Utils from "./Utils";

export default class Ares {
    static getBasic(ico) {
        return new Promise((resolve, reject) => {
            axios.get(Utils.buildUrl(['api', 'ares', 'basic']),{ params: { ico: ico } }).then((response) => {
                let data = response.data;
                let ares = new Ares(data.ico, data.dic, data.name, data.street, data.city, data.zip, data.country);
                resolve(ares);
            });
        });
    }

    // static parseFromBasic(dom_str) {
    //     let parser = new DOMParser();
    //     let dom = parser.parseFromString(dom_str,"text/xml");
    //
    //     let ico = dom.querySelector('D:ICO').innerHTML;
    //     let dic = dom.querySelector('D:DIC').innerHTML;
    //     let name = dom.querySelector('D:OF').innerHTML;
    //
    //     let street = dom.querySelector('D:NU').innerHTML;
    //     let cd = dom.querySelector('D:CD').innerHTML;
    //     let co = dom.querySelector('D:CO').innerHTML;
    //     let address = street + " " + cd + "/" + co;
    //
    //     let city = dom.querySelector('D:N').innerHTML;
    //     let zip = dom.querySelector('D:PSC').innerHTML;
    //     let country = dom.querySelector('D:NS').innerHTML;
    //
    //     return new Ares(ico, dic, name, address, city, zip, country);
    // }

    constructor(ico, dic, name, street, city, zip, country) {
        this.ico = ico;
        this.dic = dic;
        this.name = name;
        this.street = street;
        this.city = city;
        this.zip = zip;
        this.country = country;
    }
}