<template>
    <v-card min-height="500px">
        <v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="removePlace" alt="Odebrat" title="Odebrat">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
            <v-container fluid >
                <v-row wrap>
                    <v-col cols="8">
                        <InfoAutocomplete v-model="item.place" url="/api/places/search" :label="label" v-slot="props">
                            <PlaceInfo :item="props.item"></PlaceInfo>
                        </InfoAutocomplete>
                    </v-col>
                    <v-col cols="4">
                        <PlaceFormDialog v-model="newPlace" :dialog="dialog"
                                         @open="dialog = true" @close="dialog = false" @saved="newUpdateItem">
                        </PlaceFormDialog>
                        <PlaceFormDialog v-if="editPlace" v-model="editPlace" :dialog="editDialog" new-label="Upravit místo"
                                         @open="editDialog = true" @close="editDialog = false" @saved="editUpdateItem">
                        </PlaceFormDialog>
                    </v-col>
                    <v-col cols="12">
                        <TextArea v-model="item.notes" :label="notesLabel" :visible="true"></TextArea>
                    </v-col>
                    <v-col cols="12">
                        <DateTimeRangePicker :datetime_from="item.time_from" :datetime_to="item.time_to"
                                             @input-from="setTimeFrom" @input-to="setTimeTo"
                                             :label_from="timeFromLabel" :label_to="timeToLabel"
                        ></DateTimeRangePicker>
                    </v-col>
                    <v-col cols="12">
                        <TextArea v-model="item.time_notes" :label="timeNotesLabel" :visible="true"></TextArea>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import InfoAutocomplete from "./InfoAutocomplete";
    import PlaceFormDialog from "./PlaceFormDialog";
    import PlaceInfo from "./PlaceInfo";
    import Place from "../models/Place";
    import TextArea from "./TextArea";
    import moment from "moment";
    import DateTimeRangePicker from "./DateTimeRangePicker";

    export default {
        name: "ContractPlaceForm",
        props: ['value', 'idx', 'label', 'notesLabel', 'timeFromLabel', 'timeToLabel', 'timeNotesLabel'],
        components: {TextArea, DateTimeRangePicker, PlaceInfo, PlaceFormDialog, InfoAutocomplete},

        data: () => {
            return {
                item: null,
                dialog: false,
                editPlace: null,
                editDialog: false,
                newPlace: null,
                dateTimeFormat: 'DD. MM. YYYY HH:mm:ss'
            };
        },

        beforeMount() {
            this.assignValue(this.value);
        },

        methods: {
            removePlace() {
                this.$emit('remove-place', this.idx);
            },

            setTimeFrom(t) {
                this.item.time_from = t;
            },

            setTimeTo(t) {
                this.item.time_to = t;
            },

            assignValue(val) {
                this.item = val;
                this.assignPlace(this.item);
            },

            assignPlace(val) {
                if (val && val.place) {
                    this.editPlace = new Place(this.value.place);
                } else {
                    this.editPlace = null;
                }
            },

            newUpdateItem() {
                this.item.place = Object.assign({}, this.newPlace.attributes);
                this.editPlace = this.newPlace;
                this.dialog = false;
                this.newPlace = null;
            },

            editUpdateItem() {
                this.item.place = Object.assign({}, this.editPlace.attributes);
                this.editDialog = false;
            }
        },

        watch: {
            item: {
                handler(val) {
                    this.assignPlace(val);
                    this.$emit('input', val);
                },
                deep: true
            },

            value(val) {
                this.assignValue(val);
            }
        }
    }
</script>

<style scoped>

</style>