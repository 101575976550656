<template>
    <v-text-field v-model="model" :label="label" :type="type" :disabled="disabled" outlined dense></v-text-field>
</template>

<script>
    export default {
        name: "TextField",
        props: {
            'value': {},
            'label': {},
            'type': { default: 'text' },
            'disabled': { default: false, type: Boolean }
        },

        data: function() {
            return {
                model: ''
            };
        },

        created() {
            this.model = this.value;
        },

        watch: {
            value(val) {
                this.model = this.value;
            },

            model(val) {
                this.$emit('input', val);
            }
        }
    }
</script>

<style scoped>

</style>
