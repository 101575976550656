// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable"
import Tenant from "../Tenant";

export default createConsumer(getWebSocketUrl)

function getWebSocketUrl() {
    return '/cable?tenant_schema=' + Tenant.current;
}
