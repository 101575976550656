<template>
    <a :href="'mailto:' + email" v-if="email" class="email">{{ email }}</a>
</template>

<script>
    export default {
        name: "EmailInfo",

        props: ['email']
    }
</script>

<style scoped lang="scss">
    .email:not(:first-child):before {
        content: ', ';
        margin-right: 1rem;
    }
</style>
