export default class Tenant {
    static get current() {
        this._schema = this._schema || this.getTenantFromPath();
        return this._schema;
    }

    static set current(schema) {
        this._schema = schema || 'public';
    }

    static getTenantFromPath() {
        let tenant = 'public';
        let re = /^\/([^\/]*).*/;
        let path = window.location.pathname || '';
        let found = path.match(re);
        if (found && found.length === 2) {
            tenant = found[1] || 'public';
        }
        return tenant;
    }
}