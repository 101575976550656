import Model from "../Model";
import Utils from "../Utils";
import axios from "axios";
import ErrorHandler from "../ErrorHandler";

export default class Partner extends Model {
    get url() {
        return '/api/partners';
    }

    invoicingAddress() {
        // if (this.attributes.addresses) {
        //     const invoicingAddresses = this.attributes.addresses.filter(address => address.invoicing);
        //     if (invoicingAddresses.length > 0) {
        //         return invoicingAddresses[0];
        //     }
        // }

        return this.attributes.invoicing_address;
    }

    registerInFakturoid(fakturoid_type) {
        let id = this.attributes.id;
        return new Promise((resolve, reject) => {
            let url = Utils.buildUrl([this.url, id, 'register_in_fakturoid']);
            axios.get(url, { params: { fakturoid_type } })
                .then(response => {
                    this.attributes = response.data;
                    resolve(this);
                })
                .catch(error => {
                    ErrorHandler.raiseError('Chyba při ukládání.', error);
                    reject(error);
                });
        });
    }
}
