import Tenant from "./Tenant";

export default class Utils {
    static buildUrl(items = [], params={}) {
        let normalized_items = [];
        for (let i=0; i<items.length; ++i) {
            let splitted = items[i].split('/');
            for (let j=0; j<splitted.length; ++j) {
                if (splitted[j]) {
                    normalized_items.push(splitted[j]);
                }
            }
        }

        let normalized_params = [];
        for (let key in params) {
            normalized_params.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
        }

        let url = '/' + Tenant.current + '/' + normalized_items.join('/');
        if (normalized_params.length > 0) {
            url = url + '?' + normalized_params.join('&');
        }

        return url;
    }
}
