<template>
    <div class="mx-3">
        <v-row row wrap>
            <h1>Kontaktní osoby</h1>
            <v-spacer></v-spacer>
            <ContactFormDialog v-model="editedItem" :dialog="dialog" :partner="partner" :invoicing="invoicing"
                               @open="openDialog" @close="checkClose" @saved="updateItem"></ContactFormDialog>
        </v-row>

        <SearchField v-model="search" label="Hledat"></SearchField>

        <v-row row wrap>
            <v-col cols="12">
                <v-data-table
                        :headers="headers"
                        :items="invoicingItems"
                        :options.sync="pagination"
                        :server-items-length="totalItems"
                        :loading="loading"
                        class="elevation-1"
                        :footer-props="footerProps"
                        sort-by="name"
                >
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item.attributes.name }}</td>
                            <td v-if="showPartner">
                                <router-link :to="buildUrl(['partners', props.item.attributes.partner.id])">{{ props.item.attributes.partner.name }}</router-link>
                            </td>
                            <td>{{ props.item.attributes.job_title }}</td>
                            <td>
                                <EmailInfo v-for="email in props.item.attributes.emails" :email="email"></EmailInfo>
                            </td>
                            <td>
                                <PhoneInfo v-for="phone in props.item.attributes.phones" :phone="phone"></PhoneInfo>
                            </td>
                            <td>
                                <v-icon small class="mr-2" @click="goToEditItem(props.item)">mdi-pencil</v-icon>
                                <v-icon small @click="deleteItem(props.item)">mdi-delete</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ContactsCollection from '../collections/Contacts';
    import Contact from '../models/Contact';
    import editable from '../mixins/editable';
    import utils from '../mixins/utils';
    import EmailInfo from "./EmailInfo";
    import PhoneInfo from "./PhoneInfo";
    import ContactFormDialog from "./ContactFormDialog";
    import SearchField from "./SearchField";

    export default {
        name: "Contacts",

        props: {
            'partner': { required: false },
            'showPartner': { required: false, default: true },
            'invoicing': { required: false, default: false, type: Boolean },
        },

        mixins: [editable, utils],

        components: {SearchField, ContactFormDialog, PhoneInfo, EmailInfo},
        
        data: () => {
            return {
                controller: 'contacts',
                itemClass: Contact,
                collectionClass: ContactsCollection,
            };
        },

        created() {
            if (this.partner) {
                this.fetchOptions.partner_id = this.partner.id;
            }
        },

        computed: {
            headers() {
                let h = [];
                h.push({ text: 'Jméno', value: 'name' });
                if (this.showPartner) h.push({ text: 'Partner', value: 'partners.name' });
                h.push({ text: 'Funkce', value: 'job_title' });
                h.push({ text: 'E-maily', value: 'emails' });
                h.push({ text: 'Telefony', value: 'phones' });
                h.push({ text: 'Akce', value: 'action', sortable: false});

                return h;
            },

            invoicingItems() {
                if (this.invoicing) {
                    return this.items.filter(item => item.attributes.invoicing);
                }

                return this.items;
            },
        },

        methods: {
            checkClose() {
                this.partner ? this.closeDialog() : this.goToCloseDialog();
            }
        }
    }
</script>

<style>
    .client {
        background-color: lightgreen;
    }

    .carrier {
        background-color: lightblue;
    }
</style>
