<template>
    <div>
            <v-navigation-drawer permanent app :clipped="true">
                <v-list>
                    <v-list-item v-for="link in links()">
                        <v-list-item-title>
                            <v-btn :to="link.to" text block>{{ link.title }}</v-btn>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>
            <router-view />
    </div>
</template>

<script>
    import Utils from "../Utils";

    export default {
        name: "Reports",
        data: () => {
            return {
            };
        },
        methods: {
            links() {
                return [
                    { to: Utils.buildUrl(['reports', 'expenses']), title: 'Výdaje' },
                    { to: Utils.buildUrl(['reports', 'incomes']), title: 'Příjmy' },
                    { to: Utils.buildUrl(['reports', 'profit']), title: 'Zisk' }
                ];
            }
        }
    }
</script>

<style scoped>

</style>