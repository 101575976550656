<template>

</template>

<script>
    import axios from 'axios';
    import Utils from "../Utils";

    export default {
        name: "setup",

        data: () => {
            return {
                isFetching: false,
                editedItem: {},
            };
        },

        created() {
            this.editedItem = Object.assign({}, this.defaultItem);
        },

        mounted() {
            this.$nextTick(function () {
                this.fetchSetup();
            });
        },

        methods: {
            fetchSetup() {
                if (!this.isFetching) {
                    this.isFetching = true;
                    axios.get(Utils.buildUrl([this.url])).then((result) => {
                        this.editedItem = Object.assign({}, result.data);
                    }, (error) => {
                        console.log(error);
                        this.$root.$emit('error', 'Nelze otevřít pro editaci.', error);
                    }).finally(() => this.isFetching = false);
                }

            },

            saveSetup() {
                axios.put(Utils.buildUrl([this.url]), this.editedItem)
                    .then(response => {
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            }
        }

    }
</script>

<style scoped>

</style>