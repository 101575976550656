<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Přihlašovací formulář</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-text-field v-model="tenant" prepend-icon="mdi-office-building" name="tenant" placeholder="Společnost" type="text" :readonly="getTenant() !== ''"></v-text-field>
                        <v-text-field v-model="email" prepend-icon="mdi-account" name="login" placeholder="E-mail" type="text"></v-text-field>
                        <v-text-field v-model="password" id="password" prepend-icon="mdi-lock" name="password" placeholder="Heslo" type="password"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click.prevent="login">Přihlásit</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from 'axios';
    import Tenant from "../Tenant";
    import Utils from "../Utils";

    export default {
        name: "Login",

        data: () => {
            return {
                tenant: '',
                email: '',
                password: ''
            };
        },

        beforeMount() {
            this.tenant = this.getTenant();
        },

        methods: {
            getTenant() {
                return Tenant.current === 'public' ? '' : Tenant.current;
            },

            login() {
                let old_tenant = Tenant.current;
                Tenant.current = this.tenant;
                axios.post(Utils.buildUrl(['api', 'users', 'login']), { email: this.email, password: this.password })
                    .then((response) => {
                        if (response.data.logged) {
                            this.$root.$emit('user-logged', response.data.user);
                        } else {
                            Tenant.current = old_tenant;
                        }
                    })
                    .catch((reason) => {
                        Tenant.current = old_tenant;
                    });
            }
        }
    }
</script>

<style scoped>

</style>