<template>
    <span class="place-info" v-if="item">
        {{ item.name }}, {{ item.street }}, {{ item.zip }}  {{ item.city }}, {{ item.country }}<br />
        <div v-if="notesEnabled">POZNÁMKY: <pre :class="{ notes_present: (item.notes !== null && item.notes !== '') }">{{ item.notes }}</pre></div>
    </span>
</template>

<script>
    export default {
        name: "PlaceInfo",

        props: { 'item': {}, 'notesEnabled': { default: true } }
    }
</script>

<style scoped>

</style>