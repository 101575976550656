import moment from 'moment'

export default  {
    methods: {
        formatDate(str) {
            if (!str) {
                return '';
            }
            // return (new Date(str)).toLocaleString();
            return moment(str).format('DD. MM. YYYY HH:mm');
        },

        formatDateOnly(str) {
            if (!str) {
                return '';
            }
            return moment(str).format('DD. MM. YYYY');
        },

        formatDateYMD(str) {
            if (!str) {
                return '';
            }
            return moment(str).format('YYYY-MM-DD');
        },

        formatDayMonth(str) {
            if (!str) {
                return '';
            }
            return moment(str).format('DD. MM.');
        },

        formatDateRange(from, to) {
            if (!from) {
                return this.formatDate(to);
            }
            if (!to) {
                return this.formatDate(from);
            }

            let from_m = moment(from);
            let to_m = moment(to);
            if (from_m.year() === to_m.year() && from_m.month() === to_m.month() && from_m.day() === to_m.day()) {
                // same day
                return from_m.format('DD. MM. YYYY') + ' -- ' + from_m.format('HH:mm') + ' - ' + to_m.format('HH:mm');
            } else if (from_m.year() === to_m.year() && from_m.month() === to_m.month()) {
                // same month
                return from_m.format('DD. ') + ' - ' + to_m.format('DD. MM. YYYY') + ' -- ' + from_m.format('HH:mm') + ' - ' + to_m.format('HH:mm');
            } else if (from_m.year() === to_m.year()) {
                // same year
                return from_m.format('DD. MM. ') + ' - ' + to_m.format('DD. MM. YYYY') + ' -- ' + from_m.format('HH:mm') + ' - ' + to_m.format('HH:mm');
            } else {
                return from_m.format('DD. MM. YYYY') + ' - ' + to_m.format('DD. MM. YYYY') + ' -- ' + from_m.format('HH:mm') + ' - ' + to_m.format('HH:mm');
            }
        }
    }
}
