<template>
    <v-card min-height="950">
        <v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="removeCarrier" alt="Odebrat" title="Odebrat">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
            <v-container fluid >
                <v-row wrap>
                    <v-col cols="8">
                        <InfoAutocomplete v-model="item.partner" url="/api/partners/search" label="Dopravce" v-slot="props">
                            <PartnerInfo :item="props.item"></PartnerInfo>
                        </InfoAutocomplete>
                    </v-col>
                    <v-col cols="4">
                        <PartnerFormDialog v-model="newCarrierItem" :dialog="newCarrierDialog"
                                           @open="newCarrierDialog = true" @close="newCarrierDialog = false"
                                           @saved="newCarrierUpdateItem"></PartnerFormDialog>
                        <a v-if="item.partner && item.partner.id"
                           :href="buildUrl(['partners', item.partner.id])"
                           target="_blank"
                        ><v-btn color="primary" dark class="mb-2"><v-icon>mdi-open-in-new</v-icon>PODROBNOSTI</v-btn></a>
<!--                        <PartnerFormDialog v-if="editCarrierItem" v-model="editCarrierItem" :dialog="editCarrierDialog"-->
<!--                                           @open="editCarrierDialog = true" @close="editCarrierDialog = false"-->
<!--                                           @saved="editCarrierUpdateItem" new-label="Upravit partnera"></PartnerFormDialog>-->
                    </v-col>
                    <v-col cols="8">
                        <InfoSelect v-model="item.contact" url="/api/contacts/search" label="Kontaktní osoba"
                                          :params="contactParams" :use-first="true" v-slot="props">
                            <ContactInfo :item="props.item"></ContactInfo>
                        </InfoSelect>
                    </v-col>
                    <v-col cols="4">
                        <ContactFormDialog :partner="item.partner" v-model="newContactItem" :dialog="newContactDialog"
                                           @open="newContactDialog = true" @close="newContactDialog = false"
                                           @saved="newContactUpdateItem"></ContactFormDialog>
<!--                        <ContactFormDialog v-if="editContactItem" :partner="item.partner" v-model="editContactItem" :dialog="editContactDialog"-->
<!--                                           @open="editContactDialog = true" @close="editContactDialog = false"-->
<!--                                           @saved="editContactUpdateItem" new-label="Upravit kontakt"></ContactFormDialog>-->
                    </v-col>

                    <v-col cols="1">
                        <v-icon v-if="!item.number" @click="generateOrderNumber()" class="pt-2">mdi-settings-transfer</v-icon>
                    </v-col>
                    <v-col cols="4">
                        <TextField v-model="item.order_number" label="Pozice"></TextField>
                    </v-col>
                    <v-col cols="3">
                        <TextField v-model="item.due_days" label="Splatnost ve dnech"></TextField>
                    </v-col>
                    <v-col cols="4">
                        Číslo objednávky: {{ item.number }}
                    </v-col>
                    <v-col cols="4">
                        <TextField v-model="item.price" label="Cena"></TextField>
                    </v-col>
                    <v-col cols="4">
                        <v-select v-model="item.price_currency" :items="currencyList" label="Měna" solo></v-select>
                    </v-col>
                    <v-col cols="4">
                        Cena v CZK: {{ item.price_czk }}
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="item.price_notes"
                                label="Poznámky k ceně"
                                outlined dense
                                prepend-icon="mdi-eye"
                                :background-color="$vuetify.theme.dark ? '#881212' : '#ffcccc'"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <TextArea v-model="item.notes" label="Poznámky" :visible="true"></TextArea>
                    </v-col>
<!--                    <v-col cols="12">-->
<!--                        <TextArea v-model="item.goods" label="Zboží - vyplňte v případě více dopravců" :visible="true"></TextArea>-->
<!--                    </v-col>-->
                    <v-col cols="12">
                        <TextArea v-model="item.spz" label="SPZ"></TextArea>
                    </v-col>
                    <v-col cols="12">
                        <TextField v-model="item.driver" label="Řidič"></TextField>
                    </v-col>
                    <v-col v-if="item.id" cols="12">
                        <CarrierOrders :carrier="item" :contract="contractItem" :save="true" @reload-contract="reloadContract"></CarrierOrders>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
    import InfoAutocomplete from './InfoAutocomplete';
    import PartnerInfo from "./PartnerInfo";
    import ContactInfo from "./ContactInfo";
    import currency from '../mixins/currency';
    import date from '../mixins/date';
    import PartnerFormDialog from "./PartnerFormDialog";
    import ContactFormDialog from "./ContactFormDialog";
    import OrderLinkList from "./OrderLinkList";
    import InfoSelect from "./InfoSelect";
    import Partner from "../models/Partner";
    import Contact from "../models/Contact";
    import TextArea from "./TextArea";
    import utils from "../mixins/utils";
    import TextField from "./TextField";
    import CarrierOrders from "./CarrierOrders";

    export default {
        name: "CarrierContract",

        components: {
            CarrierOrders,
            TextField,
            TextArea,
            InfoSelect,
            OrderLinkList, ContactFormDialog, PartnerFormDialog, ContactInfo, PartnerInfo, InfoAutocomplete},

        props: ['value', 'idx', 'contractItem'],

        mixins: [currency, date, utils],

        data: function () {
            return {
                item: {},
                newCarrierItem: null,
                newCarrierDialog: false,
                editCarrierItem: null,
                editCarrierDialog: false,
                newContactItem: null,
                newContactDialog: false,
                editContactItem: null,
                editContactDialog: false,
                isGenerating: false,
                contract: null
            };
        },

        beforeMount() {
            this.item = this.value;
            this.editCarrierItem = new Partner(this.item.partner);
            this.editContactItem = this.item.contact ? new Contact(this.item.contact) : null;
        },

        watch: {
            item: {
                handler(val) {
                    this.$emit('input', val);
                    this.editCarrierItem = val.partner ? new Partner(val.partner) : null;
                    this.editContactItem = val.contact ? new Contact(val.contact) : null;
                },
                deep: true
            },

            value(val) {
                this.item = val;
                this.editCarrierItem = new Partner(this.item.partner);
                this.editContactItem = new Contact(this.item.contact);
            }
        },

        computed: {
            contactParams() {
                if (this.item.partner) {
                    return {partner_id: this.item.partner.id};
                } else {
                    return {partner_id: null};
                }
            }
        },

        methods: {
            reloadContract(contract) {
                this.$emit('reload-contract', contract);
            },

            removeCarrier() {
                this.$emit('remove-carrier', this.idx);
            },

            newCarrierUpdateItem() {
                this.item.partner = Object.assign({}, this.newCarrierItem.attributes);
                this.newCarrierDialog = false;
            },

            editCarrierUpdateItem() {
                this.item.partner = Object.assign({}, this.editCarrierItem.attributes);
                this.newCarrierDialog = false;
            },

            newContactUpdateItem() {
                this.item.contact = Object.assign({}, this.newContactItem.attributes);
                this.newContactDialog = false;
            },

            editContactUpdateItem() {
                this.item.contact = Object.assign({}, this.editContactItem.attributes);
                this.editContactDialog = false;
            },

            generateOrderNumber() {
                this.item.generate_order_number = true;
                this.$emit('save');
            },

            // createOrder(lang) {
            //     if (this.isGenerating) return;
            //     this.isGenerating = true;
            //     axios.get(Utils.buildUrl(['api', 'contracts', this.item.contract_id, 'generate_order']), {
            //         params: {
            //             carrier_contract_id: this.item.id,
            //             lang: lang
            //         }
            //     }).then(response => {
            //         let carrier_contract = response.data.carrier_contract;
            //         this.item.number = carrier_contract.number;
            //         let new_a = false;
            //         let a = document.querySelector('a#auto-download');
            //         if (!a) {
            //             a = document.createElement('a');
            //             a.id = 'auto-download';
            //             a.target = '_blank';
            //             new_a = true;
            //         }
            //         let blob = new File([atob(response.data.pdf)], response.data.file_name, {"type": "application/pdf"});
            //         a.href = URL.createObjectURL(blob);
            //         // a.href = 'data:application/pdf;filename=' + response.data.file_name + ';base64,' + response.data.pdf;
            //         // a.download = response.data.file_name;
            //         if (new_a) {
            //             document.body.appendChild(a);
            //         }
            //         a.click();
            //     })
            //         .catch(error => {
            //             ErrorHandler.raiseError('PDF nelze vygenerovat.', error);
            //         })
            //         .finally(() => {
            //             this.isGenerating = false;
            //         });
            // }
        }
    }
</script>

<style scoped>

</style>