import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

import Contracts from './components/Contracts';
import ContractView from './components/ContractView';
import Partners from './components/Partners';
import PartnerView from './components/PartnerView';
import Contacts from './components/Contacts';
import Places from './components/Places';
import PlaceView from './components/PlaceView';
import Users from './components/Users';
import Reports from './components/Reports';
import ReportExpenses from './components/ReportExpenses';
import ReportIncomes from './components/ReportIncomes';
import ReportProfit from "./components/ReportProfit";
import Setup from './components/Setup';
import SetupCompany from './components/SetupCompany';
import SetupPdf from './components/SetupPdf';
import SetupLogo from './components/SetupLogo';
import TenantHome from "./components/TenantHome";
import SetupDefaults from "./components/SetupDefaults";
import SetupBackups from "./components/SetupBackups";
import Orders from './components/Orders';
import Invoices from './components/Invoices';
import Expenses from "./components/Expenses.vue";

export default new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/:tenant_schema',
      name: 'tenant_home',
      component: TenantHome,
      children: [
        {
          path: 'contracts',
          name: 'contracts',
          component: Contracts,
        },
        {
          path: 'orders',
          name: 'orders',
          component: Orders,
        },
        {
          path: 'invoices',
          name: 'invoices',
          component: Invoices,
        },
        {
          path: 'expenses',
          name: 'expenses',
          component: Expenses,
        },
        {
          path: 'contracts/:id/:action',
          name: 'contracts_edit',
          component: Contracts
        },
        {
          path: 'contracts/:id',
          name: 'contract',
          component: ContractView
        },
        {
          path: 'partners/:id',
          name: 'partner',
          component: PartnerView
        },
        {
          path: 'partners',
          name: 'partners',
          component: Partners
        },
        {
          path: 'partners/:id/:action',
          name: 'partner_edit',
          component: Partners
        },
        {
          path: 'contacts',
          name: 'contacts',
          component: Contacts
        },
        {
          path: 'contacts/:id/:action',
          name: 'contact_edit',
          component: Contacts
        },
        {
          path: 'places/:id',
          name: 'place',
          component: PlaceView
        },
        {
          path: 'places',
          name: 'places',
          component: Places
        },
        {
          path: 'places/:id/:action',
          name: 'place_edit',
          component: Places
        },
        {
          path: 'reports',
          name: 'reports',
          component: Reports,
          children: [
            {
              path: 'expenses',
              component: ReportExpenses
            },
            {
              path: 'incomes',
              component: ReportIncomes
            },
            {
              path: 'profit',
              component: ReportProfit
            }
          ]
        },
        {
          path: 'setup',
          name: 'setup',
          component: Setup,
          children: [
            {
              path: 'company',
              component: SetupCompany
            },
            {
              path: 'pdf',
              component: SetupPdf
            },
            {
              path: 'logo',
              component: SetupLogo
            },
            {
              path: 'defaults',
              component: SetupDefaults
            },
            {
              path: 'backups',
              component: SetupBackups
            }
          ]
        },
        {
          path: 'users',
          name: 'users',
          component: Users
        },
        {
          path: 'users/:id/:action',
          name: 'user_edit',
          component: Users
        }
      ]
    }
  ]
});
