import axios from 'axios';
import ErrorHandler from "./ErrorHandler";
import Utils from "./Utils";

export default class Collection {
    constructor() {
        this._models = [];
    }

    get models() {
        return this._models;
    }

    get url() {
        throw 'Not implemented.';
    }

    get model() {
        throw 'Not implemented.';
    }

    fetch(params={}, options={ notifications: true }) {
        let url = Utils.buildUrl([this.url]);
        return new Promise((resolve, reject) => {
            axios.get(url, { params: params })
                .then(response => {
                    let m = this.model;
                    if (response.data.models) {
                        response.data.models.forEach((record) => {
                            this._models.push(new m(record));
                        });
                    }
                    let totalItems = response.data.totalItems || 0;
                    resolve({ models: this.models, totalItems: totalItems });
                })
                .catch(error => {
                    if (options.notifications === true) {
                        ErrorHandler.raiseError('Chyba při čtení kolekce.', error);
                    }
                    reject(error);
                });
        });
    }
}