<template>
    <div class="partner-info" v-if="item">
        {{ item.name }}, {{ item.street }}, {{ item.zip }}  {{ item.city }}, {{ item.country }}<br />
        <div v-if="!short">tel.: <PhoneInfo :phone="item.phone"></PhoneInfo>, email: {{ item.email }},</div>
        Raal: {{ item.raal }}, Timocom: {{ item.timocom }}, ICO: {{ item.ico }}<br />
        <div v-if="!short">POZNÁMKY: <pre :class="{ notes_present: (item.notes !== null && item.notes !== '') }">{{ item.notes }}</pre></div>
    </div>
</template>

<script>
    import PhoneInfo from "./PhoneInfo";
    export default {
        name: "PartnerInfo",
        components: {PhoneInfo},
        props: { 'item': {}, short: { default: false } }
    }
</script>

<style scoped>

</style>