import Utils from "../Utils";

export default {
    props: {
        saveSearch: { default: true }
    },

    data: () => {
        return {
            dialog: false,
            pagination: { itemsPerPage: 50, page: 1 },
            items: [],
            totalItems: 0,
            loading: false,
            editedItem: {},
            search: '',
            fetchOptions: {},
            footerProps: { 'items-per-page-options': [50, 100, 150] }
        };
    },

    created() {
        this.newEditedItem();
    },

    mounted() {
        this.$nextTick(function () {
            this.fetchItems();

            if (this.$route.params.action === 'edit') {
                let item = new this.itemClass({ id: this.$route.params.id });
                this.editItem(item);
            }

        });
    },

    watch: {
        pagination: {
            handler() {
                this.fetchItems();
            },
            deep: true
        },

        search(value) {
            if (this.searchKey !== undefined) {
                this.saveToStorage(this.searchKey, value || '');
            }
            this.fetchItems();
        },

        '$route' (to, from) {
            if (from.params.action === 'edit') {
                this.closeDialog();
            }
            if (to.params.action === 'edit') {
                let item = new this.itemClass({ id: to.params.id });
                this.editItem(item);
            }
        }
    },

    methods: {
        goToEditItem(item) {
            this.$router.push(Utils.buildUrl([this.controller, item.attributes.id, 'edit']));
        },

        goToCloseDialog() {
            if (this.$route.params.id) {
                // this.$router.push(Utils.buildUrl([this.controller]));
                // redirect after all callbacks are done
                setTimeout(() => { this.$router.back(); }, 500);
            } else {
                this.closeDialog();
            }
        },

        loadFromStorage(key) {
            return (this.saveSearch === true) ? window.localStorage.getItem(key) || '' : '';
        },

        saveToStorage(key, value) {
            if (this.saveSearch === true) {
                window.localStorage.setItem(key, value || '');
            }
        },

        newEditedItem() {
            this.editedItem = new this.itemClass();
        },

        fetchItems() {
            if (this.loading) return;

            this.loading = true;
            let collection = new this.collectionClass();
            collection.fetch({ pagination: this.pagination, search: this.search, options: this.fetchOptions })
                .then((data) => {
                    this.totalItems = data.totalItems;
                    this.items = data.models;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        editItem (item) {
            this.editedItem = item;
            this.openDialog();
        },

        deleteItem (item) {
            const index = this.items.indexOf(item);
            if (confirm('Opravdu smazat položku?')) {
                item.delete()
                    .then(() => {
                        this.items.splice(index, 1);
                        if (this.afterDelete) {
                            this.afterDelete();
                        }
                    });
            }
        },

        closeDialog () {
            this.dialog = false;
            setTimeout(() => {
                this.newEditedItem();
                if (this.cleanForm) {
                    this.cleanForm();
                }
            }, 300);
        },

        openDialog() {
            this.dialog = true;
        },

        updateItem(performUnshift = true) {
            if (this.editedItem.attributes.id) {
                let updated = false;
                for (let i = 0; i < this.items.length; ++i) {
                    if (this.items[i].attributes.id === this.editedItem.attributes.id) {
                        this.items.splice(i, 1, this.editedItem);
                        updated = true;
                    }
                }
                if (!updated) {
                    let perform = performUnshift !== false && this.performUnshift ? this.performUnshift(this.editedItem) : true;
                    if (perform) {
                        this.items.unshift(this.editedItem);
                    }
                }
                if (this.afterUpdate) {
                    this.afterUpdate();
                }
            }
        }
    }
};