<template>
    <div class="order-link">
        <v-icon @click="deleteOrder" small>mdi-delete</v-icon>
        <a :href="buildUrl(['api', 'orders', item.attributes.id, 'download'])" target="_blank">{{ formatDate(item.attributes.created_at) }} - {{ item.attributes.created_by.name }}</a>
    </div>
</template>

<script>
    import date from '../mixins/date';
    import utils from "../mixins/utils";

    export default {
        name: "OrderLink",
        mixins: [date, utils],

        props: ['item'],

        methods: {
            deleteOrder() {
                if (confirm('Smazat PDF?')) {
                    this.item.delete()
                        .then(() => {
                            this.$emit('order-deleted', this.item);
                        });
                }
            }
        }
    }
</script>

<style scoped>

</style>