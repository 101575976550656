<template>
    <div class="mb-3">
        <table class="carrier-info" v-if="item">
            <tr><td colspan="2"><PartnerInfo :item="item.partner" :short="short"></PartnerInfo></td></tr>
            <tr><th>Kontaktní osoba:</th><td><ContactInfo :item="item.contact"></ContactInfo></td></tr>
            <tr><th>Řidič:</th><td>{{ item.driver }}</td></tr>
            <tr><th>SPZ:</th><td>{{ item.spz }}</td></tr>
            <tr><th>Poznámka k dopravci</th><td>{{ item.notes }}</td></tr>
            <tr><th>Zboží</th><td>{{ item.goods }}</td></tr>
            <tr v-if="!short"><th>Číslo objednávky:</th><td>{{ item.number }}</td></tr>
            <tr v-if="!short"><th>Pozice:</th><td>{{ item.order_number }}</td></tr>
            <!--- Objednávky: <OrderLinkList v-model="item.orders"></OrderLinkList>, -->
            <tr v-if="!short"><th>Splatnost ve dnech:</th><td>{{ item.due_days }}</td></tr>
            <tr v-if="!short"><th>Cena:</th><td>{{ item.price_formatted }} {{ item.price_currency }}</td></tr>
            <tr v-if="!short"><th>Poznámka k  ceně:</th><td :class="{ notes_present: (item.price_notes !== null && item.price_notes != '') }">{{ item.price_notes }}</td></tr>
            <tr v-if="!short"><th>Cena v CZK:</th><td>{{ item.price_czk }}</td></tr>
            <tr><th>Objednávky:</th><td><CarrierOrders :carrier="item" :contract="contract.attributes" :save="false"></CarrierOrders></td></tr>
            <tr><th>Fakturace:</th><td>
                <div v-if="item.expense">
                    <div class="order-link mt-3">
                        <v-icon @click.prevent="deleteExpense" small>mdi-delete</v-icon>
                        <a v-if="item.expense.file_path" :href="buildUrl(['api', 'expenses', item.expense.id, 'download'])" target="_blank">{{ item.expense.number }}</a>
                        <a :href="item.expense.html_url" target="_blank">Do Fakturoidu</a>
                    </div>
                </div>
                <ExpenseDialog :carrier="item" :contract="contract" :save="false"></ExpenseDialog>
            </td></tr>
        </table>
    </div>
</template>

<script>
    import PartnerInfo from "./PartnerInfo";
    import ContactInfo from "./ContactInfo";
    import OrderLink from "./OrderLink";
    import OrderLinkList from "./OrderLinkList";
    import utils from "../mixins/utils";
    import CarrierCreateOrderButton from "./CarrierCreateOrderButton";
    import CarrierOrders from "./CarrierOrders";
    import ExpenseDialog from "./ExpenseDialog.vue";
    import Expense from "../models/Expense";

    export default {
        name: "CarrierInfo",
        components: {
            ExpenseDialog, CarrierOrders, CarrierCreateOrderButton, OrderLinkList, OrderLink, ContactInfo, PartnerInfo
        },
        mixins: [utils],
        props: {
            item: {},
            contract: {},
            short: { default: false },
            save: { default: true }
        },

        methods: {
            deleteExpense() {
                if (confirm('Smazat Náklad?')) {
                    let expense = new Expense(this.item.expense);
                    expense.delete()
                        .then(() => {
                            this.item.expense = null;
                            this.$emit('expense-deleted', expense);
                            window.location.reload();
                        });
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .carrier-info {
        tr {
            th, td {
                text-align: left;
                height: auto;
                border: none !important;
            }

        }
    }
</style>
