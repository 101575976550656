import Contact from "../models/Contact";
import Collection from "../Collection";

export default class Contacts extends Collection {
    get url() {
        return '/api/contacts';
    }

    get model() {
        return Contact;
    }
}