<template>
    <v-row>
        <v-col cols="6" class="pl-0 pr-1"><DateTimePicker :datetime="datetime_from" :resettime="true" :label="label_from" @input="setDateTimeFrom"></DateTimePicker></v-col>
        <v-col cols="6" class="pl-1 pr-0"><DateTimePicker :datetime="datetime_to" :resettime="true" :label="label_to" @input="setDateTimeTo"></DateTimePicker></v-col>
    </v-row>
</template>

<script>
    import DateTimePicker from "./DateTimePicker";
    import moment from 'moment';

    export default {
        name: "DateTimeRangePicker",
        props: ['datetime_from', 'datetime_to', 'label_from', 'label_to'],

        components: {DateTimePicker},

        methods: {
            setDateTimeFrom(val) {
                if (!this.datetime_to) {
                    this.$emit('input-to', val);
                } else if (this.datetime_from) {
                    // let dt_from_old = moment(this.datetime_from);
                    let dt_from_new = moment(val);
                    let d_from_new = moment(dt_from_new.format('YYYY-MM-DD'));
                    let t_from_new = moment(`2000-01-01 ${dt_from_new.format('HH:mm')}`);
                    let dt_to_old = moment(this.datetime_to);
                    let d_to_old = moment(dt_to_old.format('YYYY-MM-DD'));
                    let t_to_old = moment(`2000-01-01 ${dt_to_old.format('HH:mm')}`);

                    // let diff_to = dt_to_old.diff(dt_from_old);
                    // let dt_to_new = null;
                    // if (diff_to > 0) {
                    //     dt_to_new = dt_from_new.add(diff_to);
                    // } else if (diff_to <= 0) {
                    //     dt_to_new = dt_from_new;
                    // }
                    // let dt_to_new_formatted = dt_to_new.format('YYYY-MM-DD HH:mm');
                    // this.$emit('input-to', dt_to_new_formatted);

                    let diff_to = d_to_old.diff(d_from_new);
                    let d_to_new = null;
                    if (diff_to < 0) {
                        // new is bigger
                        d_to_new = d_from_new;
                    } else {
                        d_to_new = d_to_old;
                    }

                    let diff_to_t = t_to_old.diff(t_from_new);
                    let t_to_new = null;
                    if (diff_to_t < 0) {
                        // new is bigger
                        t_to_new = t_from_new;
                    } else {
                        t_to_new = t_to_old;
                    }

                    let dt_to_new = moment(`${d_to_new.format('YYYY-MM-DD')} ${t_to_new.format('HH:mm')}`);
                    this.$emit('input-to', dt_to_new.format());
                }
                this.$emit('input-from', val);
            },

            setDateTimeTo(val) {
                this.$emit('input-to', val);
            }
        }
    }
</script>

<style scoped>

</style>
