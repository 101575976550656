import Collection from "../Collection";
import Message from "../models/Message";

export default class Messages extends Collection {
    get url() {
        return '/api/messages';
    }

    get model() {
        return Message;
    }
}