export default {
    props: {
        value: {},
        dialog: {},
        newLabel: {}
    },

    data: () => {
        return {
            editedItem: {},
            formDialog: false,
            isFetching: false,
            isSaving: false,
            currentItem: null
        };
    },

    methods: {
        resetForm() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.currentItem = null;
            if (this.cleanForm) {
                this.cleanForm();
            }
            if (this.fillForm) {
                this.fillForm();
            }
        },

        setupForm(item) {
            this.editedItem = Object.assign({}, this.defaultItem);
            Object.assign(this.editedItem, item.attributes);
            if (this.fillForm) {
                this.fillForm();
            }
        },

        assignAttributes(item) {
            if (item) {
                this.setupForm(item);
            } else {
                this.resetForm();
            }
        },

        fetchOrNew() {
            window.reload_possible = false;
            let attrs = {};
            if (this.value && this.value.attributes.id) {
                attrs.id = this.value.attributes.id;
            }
            if (attrs.id && attrs.id !== -1 && !this.isFetching) {
                let item = new this.itemClass(attrs);
                this.isFetching = true;
                item.fetch({get_lock: true}).then((_this) => {
                    this.$emit('input', item);
                    this.assignAttributes(item);
                    this.currentItem = item;
                }, (error) => {
                    this.close();
                    this.$root.$emit('error', 'Nelze otevřít pro editaci.', error);
                }).finally(() => this.isFetching = false);
            } else if (!attrs.id) {
                this.resetForm();
            }

        },

        close() {
            if (this.onClose) {
                this.onClose();
            }
            this.resetForm();
            window.reload_possible = true;
            this.$emit('close');
        },

        save (closeDialog = true) {
            if (this.isSaving) return;

            this.isSaving = true;

            let item = new this.itemClass(this.editedItem);
            item.save().then((response) => {
                this.currentItem = item; // if the item was new we need to unlock it
                this.$emit('input', item);
                this.$emit('saved');
                if (closeDialog) {
                    window.reload_possible = true;
                    this.$emit('close');
                }
            }).finally(() => {
                this.isSaving = false;
            });
        }
    },

    watch: {
        value(item) {
            this.assignAttributes(item);
        },

        dialog(val) {
            if (val === true) {
                // dialog has been opened externally
                // this.fetchOrNew();
            } else {
                // dialog has been closed externally
            }
            this.formDialog = val;
        },

        formDialog(val) {
            if (val === true) {
                // this.resetForm();
                this.fetchOrNew();
                this.$emit('open');
            } else {
                this.close();
            }
        }
    },

    created() {
        this.resetForm();
        this.formDialog = this.dialog;
    },

    mounted() {
        this.$nextTick(function () {
            if (this.formDialog) {
                this.fetchOrNew();
            }
        });
    }
}