import Utils from "../Utils";

export default {
    methods: {
        buildUrl(items = [], params = {}) {
            return Utils.buildUrl(items, params);
        },

        isLocked(item) {
            if (item.attributes.locked_by && window.current_user.id !== item.attributes.locked_by.id) {
                return true;
            }
            return false;
        },

        forceUnlock(item) {
            if (confirm('Natvrdo zpřístupnit zakázku pro editaci?')) {
                item.forceUnlock();
            }
        },

        priceReplaceCommaWithDot(str) {
            if (str && str instanceof String) {
                return str.replace(/,(.*)$/, '.$1');
            }

            return str;
        }
    }
}
